import React from "react";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";

const StripePay = ({
  reference_no,
  multiModeReference,
  handleSyncBooking,
  total,
  stripePromise,
  clientSecret,
  stripePaymentIntent,
  firstName,
  lastName,
  email,
  mobile,
  airport,
  discountCode,
  handleValidate,
  responseError,
  supplierCost,
  service,
  api_tag,
  isVehicle,
  product,
  setAgree,
  agree,
  error
}) => {
  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Segoe UI, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
      },
      rules: {
        ".Label": {
          display: "flex",
          fontSize: "16px",
          fontWeight: "600",
          color: "#21303E",
          marginBottom: "15px",
        },
        ".Input": {
          width: "100%",
          height: "40px",
          border: "1px solid #70707069",
          borderRadius: "4px",
          backgroundColor: "#fff",
          padding: "10px",
          fontSize: "16px",
          fontWeight: "400",
          color: "#707070",
        },
      },
    },
  };

  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        <StripeForm
          total={total}
          handleSyncBooking={handleSyncBooking}
          reference_no={reference_no}
          multiModeReference={multiModeReference}
          firstName={firstName}
          lastName={lastName}
          email={email}
          mobile={mobile}
          airport={airport}
          clientSecret={clientSecret}
          stripePaymentIntent={stripePaymentIntent}
          discountCode={discountCode}
          handleValidate={handleValidate}
          responseError={responseError}
          service={service}
          supplierCost={supplierCost}
          isVehicle={isVehicle}
          product={product}
          setAgree={setAgree}
          agree={agree}
          error={error}
        />
      </Elements>
    </>
  );
};

export default StripePay;
