import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import { GoChevronDown } from "react-icons/go";

const SingleAccordion = ({ title, description }) => {
  const [active, setActive] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: active,
    duration: 300,
  });

  const handleToggle = () => {
    setActive(!active);
  };

  return (
    <div className="single-accordion w-3/5 mx-auto shadow-[0_0px_5px_#00000029] bg-default rounded mt-10 max-md:w-[98%]">
      <div
        className={`accordion-title sm:flex items-center justify-between grid grid-cols-[1fr_40px] p-5 ${
          active ? "shadow-[0_3px_9px_#00000029]" : ""
        }`}
        {...getToggleProps({ onClick: handleToggle })}
      >
        <h3 className="md:text-[18px] text-base text-secondary font-semibold flex items-center">
          {title}
        </h3>
        <div
          className={`icon bg-secondary flex items-center justify-center w-[40px] h-[40px] rounded-[50%] transition-all duration-[500ms] ${
            active ? "" : "rotate-[-90deg]"
          }`}
        >
          <GoChevronDown className="text-2xl text-white pt-[3px] pr-[1px]" />
        </div>
      </div>
      <div className="content" {...getCollapseProps()}>
        <div className="p-5">
          <p className="md:text-[16px] text-[14px] leading-9">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleAccordion;
