import React from "react";
import parse from "html-react-parser";

const Overview = ({ product }) => {
  return (
    product?.details && (
      <div>{parse(product?.details)}</div>
    )
  );
};

export default Overview;
