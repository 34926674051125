import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import apiCall from "../common/config/apiCall";
import apiKey from "../common/config/apiKey";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import BookingDetails from "../containers/Booking/BookingDetails";
import Loader from "../common/Components/Loader/Loader";
import homeGif from "../common/images/book-now.gif";
import Seo from "../common/Components/Seo/Seo";

const Booking = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getAirport = params.get("airport");
  const getPromocode = params.get("promocode");
  const singleProduct = params.get("product");
  const sku = params.get("sku");
  const [site, setSite] = useState({});
  const [airports, setAirports] = useState([]);
  const [product, setProduct] = useState({});
  const [state, setState] = useState("loading");
  const [cancellation, setCancellation] = useState(false);
  const [sms, setSMS] = useState(false);
  const [vehicles, setVehicles] = useState([
    {
      model: "",
      color: "",
      make: "",
      reg_no: "",
    },
  ]);

  useEffect(() => {
    if (product?.api_tag || (product?.option_id && product?.search_id)) {
      setVehicles([
        {
          model: "",
          color: "",
          make: "",
          reg_no: "",
        },
      ]);
    } else {
      setVehicles([
        {
          model: "TBC",
          color: "TBC",
          make: "TBC",
          reg_no: "TBC",
        },
      ]);
    }
  }, [product]);

  useEffect(() => {
    (async () => {
      setState("loading");
      if (getDeparture || getAirport || getArrival || getPromocode) {
        // @ts-ignore
        const responseProduct = await apiCall(
          "get",
          `/product?key=${apiKey}&departure=${`${moment(getDeparture).format(
            "DD-MM-yyyy"
          )} ${moment(getDeparture).format("HH:mm")}`}&arrival=${`${moment(
            getArrival
          ).format("DD-MM-yyyy")} ${moment(getArrival).format(
            "HH:mm"
          )}`}&sku=${singleProduct}&discount_code=${
            getPromocode?.toString() || ""
          }&airport=${getAirport?.toString() || ""}&return_response=${"full"}`,
          {},
          {},
          pathname
        );

        if (responseProduct?.success) {
          setState("success");
          setAirports([
            {
              name: responseProduct?.data?.airport_name,
              code: responseProduct?.data?.airport_code,
              terminal: responseProduct?.data?.terminals,
            },
          ]);
          setProduct(responseProduct?.data);
          setSite(responseProduct?.data?.site);
        }

        if (
          !responseProduct?.success &&
          responseProduct?.response_code == 400
        ) {
          const params = new URLSearchParams({
            departure: getDeparture,
            arrival: getArrival,
            promocode: getPromocode,
            airport: getAirport,
            product: (singleProduct || "").toString(),
          });
          //   push(`/search?${params}`);
        }
      }
    })();
  }, [getDeparture, getAirport, getArrival, getPromocode]);

  return (
    <>
      <Seo
        seo={{
          title: "Booking Page | Kangaroo Parking Heathrow ",
        }}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {Object.keys(site).length > 0 ? (
        <BookingDetails
          airports={airports}
          product={product}
          site={site}
          vehicles={vehicles}
          setVehicles={setVehicles}
          cancellation={cancellation}
          setCancellation={setCancellation}
          sms={sms}
          setSMS={setSMS}
          singleAirport={{}}
          getDeparture={getDeparture}
          getArrival={getArrival}
        />
      ) : (
        <div>{state === "loading" && <Loader imageUrl={homeGif} />}</div>
      )}
    </>
  );
};

export default Booking;
