import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Change from "../../common/Components/Change/Change";
import moment from "moment";

const Summary = ({ airports }) => {
  const [params] = useSearchParams();
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getAirport = params.get("airport");

  const [open, setOpen] = useState(false);
  const [airport, setAirport] = useState("");

  let airportsUpdate = [];

  if (airports?.length) {
    airports?.forEach((airport) =>
      airportsUpdate.push({
        label: airport?.name,
        value: airport?.code,
      })
    );
  }

  useEffect(() => {
    let findIndex = airportsUpdate.findIndex((a) => a.value === getAirport);
    setAirport(airportsUpdate[findIndex]?.label);
  }, [getAirport, airports]);

  return (
    <div className="max-container bg-primary pb-4">
      <div className=" px-6 pt-6 flex md:flex-row flex-col justify-between md:items-center items-start max-w-[1280px] mx-auto">
        <div>
          <h2 className="text-white text-xl font-bold">
            {airport} Airport Parking
          </h2>
          <p className="text-white flex items-center mt-1 text-sm">
            📅 From: {moment(getDeparture).format("ll")}{" "}
            {moment(getDeparture).format("HH:mm")}, to{" "}
            {moment(getArrival).format("ll")}{" "}
            {moment(getArrival).format("HH:mm")}
          </p>
        </div>
        <button
          onClick={() => setOpen(!open)}
          className="bg-white text-gray-700 px-4 py-2 rounded-md text-sm font-medium shadow-md md:mt-0 mt-4"
        >
          Edit your Search Here
        </button>
      </div>

      {/* Smooth Transition Search Form */}
      <div
        className={` transition-all duration-500 ease-in-out ${
          open ? " opacity-100 py-4" : "max-h-0 opacity-0"
        }`}
      >
        <Change
          airports={airports}
          open={open}
          setOpen={setOpen}
          refer="/search"
        />
      </div>
    </div>
  );
};

export default Summary;
