import React from "react";
import parse from "html-react-parser";

const DropOffProcedure = ({ product }) => {
  return (
    <>
      {product?.drop_procedure ? (
        <div>{parse(product.drop_procedure)}</div>
      ) : null}
    </>
  );
};

export default DropOffProcedure;
