import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import apiCall from "../../common/config/apiCall";
import apiKey from "../../common/config/apiKey";
import ConfirmWithSummary from "./SuccessBookingSummary/SuccessBookingSummary";

const BookingConfirm = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [params] = useSearchParams();
  
  const multi_mode_reference_no = params.get("bookingReference");
  const reference_no = params.get("reference_no");
  const transaction_id = params.get("transactionID");
  const payment_intent = params.get("payment_intent");
  const paymentMethod = params.get("paymentMethod");
  const email_payment = params.get("email_payment");
  const api_tag = params.get("api_tag");
  
  const [booking, setBooking] = useState({});
  const [state, setState] = useState("loading");

  useEffect(() => {
    (async () => {
      setState("loading");
      if (multi_mode_reference_no) {
        const responseBooking = await apiCall(
          "post",
          "/payments/update",
          {
            key: apiKey,
            multi_mode_reference_no,
            reference_no: reference_no?.split(","),
            transaction_id,
            payment_intent,
            payment_type: paymentMethod || "stripe",
            api_tag,
            email_payment,
          },
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          pathname
        );

        if (responseBooking?.success) {
          setState("success");
          setBooking(responseBooking);
        } else {
          setState("error");
        }
      }
    })();
  }, [multi_mode_reference_no]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {state === "loading" ? (
        <div className="flex flex-col items-center justify-center">
          <div className="w-12 h-12 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
          <p className="mt-3 text-blue-600 text-lg font-medium">Processing your booking...</p>
        </div>
      ) : booking?.data?.length > 0 ? (
        <ConfirmWithSummary bookingDetails={booking?.data} />
      ) : (
        <p className="text-red-500 text-lg font-medium">No booking details found.</p>
      )}
    </div>
  );
};

export default BookingConfirm;
