import React from "react";
import Grid from "./Grid";
import Loader from "../../common/Components/Loader/Loader";
import homeGif from "../../common/images/home-page.gif";

const Companies = ({ products, error, state }) => {
  if (state === "loading") {
    return <Loader imageUrl={homeGif} />;
  }

  return (
    <div className="companies max-w-[1280px] mx-auto">
      {products.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between p-4">
          {products?.map((product) => {
            return <Grid key={product.id} product={product} />;
          })}
        </div>
      ) : (
        <div>
          <p className="text-secondary text-center font-semibold sm:text-[24px] text-base h-[200px] flex justify-center items-center">
            No Companies Found
          </p>
        </div>
      )}
    </div>
  );
};

export default Companies;
