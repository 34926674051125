import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import calculateTotalPrice from "../../../common/libs/caclulateTotalPrice";
import "react-responsive-modal/styles.css";
import ModalChange from "../../../common/Components/Change/ModalChange";

const BookingSummary = ({ product, airports, cancellation, sms, vehicles }) => {
  const [params] = useSearchParams();
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getAirport = params.get("airport");
  const [airport, setAirport] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  let price = product?.payment
    ? parseFloat(product?.payment?.amount) * vehicles.length
    : parseFloat(product?.price) * vehicles.length;
  const total = calculateTotalPrice(product, price, cancellation, sms);

  let airportsUpdate = [];

  if (airports?.length) {
    airports?.map((airport) =>
      airportsUpdate.push({
        label: airport?.name,
        value: airport?.code,
      })
    );
  }

  useEffect(() => {
    const findIndex = airportsUpdate.findIndex((a) => a.value === getAirport);
    setAirport(airportsUpdate[findIndex]?.label);
  }, [getAirport]);

  return (
    <div className="booking-summary-container flex-1 mt-[80px] ">
      <div className=" mx-auto bg-white shadow-lg rounded-2xl  border border-secondary">
        <div className="relative flex justify-center w-[316px] mx-auto m-4 ">
          <img
            src={product?.company ? product?.company?.image : product.image}
            alt={product.name}
            className="w-[280px] h-[108px] object-contain bg-[#FBFBFB] rounded-[34px] px-10 py-1 shadow-md absolute -top-[100px]  border-[#66A4AC] border-[0.5px] sm:left-auto left-auto"
          />
        </div>
        <h2 className="text-[22px] font-bold text-gray-800 mt-4 text-secondary m-4">
          {product?.company ? product?.company?.name : product.name}
        </h2>
        <span className="text-[14px] text-white py-1 px-2 rounded-md inline-block mt-1 bg-primary m-4">
          {product?.company ? product?.company?.type : product.type}
        </span>
        <div className="mt-4 text-sm  border-[0.5px] border-dotted border-[#C8C8C8] p-2 m-4 rounded-md">
          <div className="flex justify-between  py-1">
            <span className="text-[#949494]">Travelling from</span>
            <span className="font-normal text-black">
              {product?.company ? product?.company?.airport?.name : airport}{" "}
              Airport
            </span>
          </div>
          <div className="flex justify-between  py-1">
            <span className="text-[#949494]">Departure Date</span>
            <span className="font-normal">
              {product?.departure_date
                ? moment(product?.departure_date).format("ll")
                : moment(getDeparture).format("ll")}
              {/* {moment(getDeparture).format("ddd, DD MMM YYYY")} */}
            </span>
          </div>
          <div className="flex justify-between  py-1">
            <span className="text-[#949494]">Departure Time</span>
            <span className="font-normal">
              {product?.departure_time
                ? product?.departure_time.slice(0, -3)
                : moment(getDeparture).format("HH:mm")}
            </span>
          </div>
          <div className="flex justify-between  py-1">
            <span className="text-[#949494]">Arrival Date</span>
            <span className="font-normal">
              {product?.arrival_date
                ? moment(product?.arrival_date).format("ll")
                : moment(getArrival).format("ll")}
            </span>
          </div>
          <div className="flex justify-between  py-1">
            <span className="text-[#949494]">Arrival Time</span>
            <span className="font-normal">
              {" "}
              {product?.arrival_time
                ? product?.arrival_time.slice(0, -3)
                : moment(getArrival).format("HH:mm")}
            </span>
          </div>
        </div>
        <div className="text-center mt-3">
          <button
            className="bg-primary text-white py-1 px-4 rounded-md text-sm"
            onClick={() => setOpen(true)}
          >
            Change
          </button>
          <ModalChange
            airports={airports}
            open={open}
            setOpen={setOpen}
            refer="/booking"
          />
        </div>
        <div className="mt-4 text-sm  border-[0.5px] border-dotted border-[#C8C8C8] p-2 m-4 rounded-md">
          {[
            {
              label: "Quote Amount",
              value: `£${
                product?.payment
                  ? product?.payment?.amount
                  : product.price_before_discount
              }`,
            },
            // { label: "Price", value: `£${product?.price}` },
            {
              label: "discount",
              value: `- £ ${
                product?.payment ? product?.payment?.discount : product.discount
              }`,
            },
            {
              label: "Sms Charge",
              value: `£ ${
                sms
                  ? product?.payment
                    ? product?.payment?.sms_charges
                    : product.sms_charges
                  : "0.00"
              }`,
            },
            {
              label: "Cancellation",
              value: `£ ${
                cancellation
                  ? product?.payment
                    ? product?.payment?.cancellation_charges
                    : product.cancellation_charges
                  : "0.00"
              }`,
            },
            {
              label: "Booking Fee",
              value: `£ ${
                product?.payment
                  ? product?.payment?.admin_charges
                  : product.admin_charges
              }`,
            },
          ].map((item, index) => (
            <div key={index} className="flex justify-between  py-1">
              <span className="text-[#949494]">{item.label}</span>
              <span className="font-normal text-black">{item.value}</span>
            </div>
          ))}
        </div>
        <div className="bg-secondary text-white text-[22px] font-normal text-center py-3 rounded-b-xl  mt-4">
          Total Amount:{" "}
          <span className="md:text-[30px] text-[25px] font-bold ml-2">
            £ {total.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;
