import React from "react";
import calculateTotalBookingAmount from "../../../common/libs/calculateTotalBookingAmount";
import parse from "html-react-parser";
import { FaCar, FaCheck } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { nextPage } from "../../../common/config/nextPage";
import Heading from "../../../common/Components/Heading/Heading";
import BookingSummary from "../../Booking/BookingSummary/BookingSummary";
import ConfirmBookingSummary from "./ConfirmBookingSummary";
// import confirm from "../../../common/images/booking-confirmation.jpg";
// import Summary from "./Summary";

const ConfirmWithSummary = ({ bookingDetails }) => {
  const [params] = useSearchParams();
  const bookingReference = params.get("bookingReference");

  return (
    <div className="mt-24 md:px-10 px-5">
      {/* <Heading title="Booking Status" >{<FaCheck />}</Heading> */}
      <div className='heading-container  md:px-10 px-5'>
    <div className="max-container">
    <div className="info text-center ">
      <h3 className='lg:text-5xl md:text-4xl small:text-3xl text-[18px] text-primary font-bold '>Booking Status</h3>
     <div className="flex justify-center mt-4"> <FaCheck  className="border-primary border rounded-full text-6xl text-primary p-2"/></div>
    </div>

  </div>
  </div>
      <div className="success-row">
        <div className="confirmation-detail">
          <div className="img">
            {/* <img src={confirm} alt="confirm image" width={150} /> */}
          </div>
          <div className="confirm-alert mt-4">
            <p className="text-center">
              Your parking booking has been completed successfully. Booking
              Reference{" "}
              <span className="font-bold text-primary">
                {" "}
                {bookingDetails[0]?.third_party_reference
                  ? bookingDetails[0]?.third_party_reference
                  : bookingReference}
              </span>
            </p>
          </div>
          {bookingDetails?.map((booking) => {
            return <ConfirmBookingSummary key={booking.id} bookingDetails={booking} />;
          })}
          <div className="btn-container">
            {/* <h5 className="another-book-btn" onClick={() => nextPage("/")}>
              <FaCar />
              Book Another Vehicle
            </h5> */}
           
          </div>
          <div
            style={{
              fontSize: "14px",
              textAlign: "justify",
              lineHeight: "35px",
            }}
            className="mt-4 mb-5"
          >
            {parse(
              bookingDetails[0]?.booking_details[0]?.company?.terms_conditions
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmWithSummary;
