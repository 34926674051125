import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


export default function TopHeader({open}) {

    const location = useLocation();
    const isHomePage = location.pathname === "/";
    return (
        <>
        <div className="relative  w-full ">
        <div  className={`bg-[#3472CD] ${isHomePage ? "opacity-50" : "opacity-100"} transition-opacity duration-300  py-4 md:h-[57px] h-[70px] w-full absolute `}></div>
        <div className="max-w-[1280px] mx-auto md:flex md:flex-row flex-col timing-container  h-[57px] relative  mt-0">
          
            <div className="w-[100%]   md-px-10 sm:px-5 px-1 ">
                <div className="wrapper max-w-[1280px] mx-auto flex md:flex-row flex-col items-center justify-between py-3">

                    <div className="flex  sm:flex-row flex-col gap-1 items-center space-x-4">
                        <div className="mail flex  items-center">
                            <a
                                className="small:text-[14px] text-[12px] text-white font-normal flex items-center"
                                href="mailto:info@parking4you.co.uk"
                            >
                                <IoIosMail className="small:text-[25px] text-[12px] text-white mr-2" />
                                Info@kangarooparking.co.uk
                            </a>
                        </div>
                        <div className="phone-number flex items-center small:text-[14px] text-[14px] text-white gap-3">
                        <img
                            alt="phone"
                            src={require("../../images/phone.png")}
                           
                            className="md:w-[35px] md:[23px] md:h-[28px] h-[23px]"
                        />
                        <div>

                            {/* <p className="text-primary font-normal md:text-[14] text-[12px]">Phone Number</p> */}

                            <a href="tel:07413 818425" className="font-normal md:text-[14] text-[12px]">07413 818425</a>
                        </div>

                    </div>

                        {/* <div className="h-6 w-[2px] bg-white"></div> */}

                        {/* <div className="mail">
                            <a
                                className="small:text-[15px] text-[14px] text-white flex items-center"
                                href="mailto:info@parking4you.co.uk"
                            >
                                <FaLocationDot className="small:text-[25px] text-[20px] text-white mr-2" />
                                icon2 Fifth Avenue 5501, Broadway, New York Morris Street.
                            </a>
                        </div> */}
                    </div>

               

                </div>
            </div>
            <div className="w-[20%] md:block hidden text-white  py-5 md-px-10 px-5">
<div className="flex items-center">
<p className="md-text-[14px] text-[12px]">Follow Us</p>
<FaFacebookF  className="text-[18px] ml-3"/>
<FaInstagram className="text-[18px] ml-3"/>
<FaYoutube className="text-[18px] ml-3"/>
</div>
            </div>

        </div>
        </div>
        </>
    );
}
