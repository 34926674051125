import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopHeader from "./TopHeader";
import BottomHeader from "./BottomHeader";
import Cookies from "js-cookie";
import OtherHeader from "./OtherHeader";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [cookie, setCookie] = useState(null);

  useEffect(() => {
    let value = Cookies.get("customCid");
    if (value) {
      setCookie(value);
    }
  }, []);

  // Hide Header on "/" (Home Page)
  if (location.pathname === "/") {
    return null;
  }

  return (
    <div className="relative w-full">
      {/* Top Header */}
      <div className="md:relative static z-10  md:pb-0 pb-3">
        <TopHeader />
      </div>

      {/* Bottom Header */}
      <div className={`relative bg-secondary  transition-all duration-300 ${isOpen ? "pb-2" : "pb-0"}`}>
        <OtherHeader isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default Header;
