import React, { useState } from "react";
import FormIntro from "./FormIntro";
import SearchForm from "../../../common/Components/SearchForm/SearchForm";
import ThemeToggle from "./ThemeToggle";
import TopHeader from "../../../common/Components/Header/TopHeader";
import BottomHeader from "../../../common/Components/Header/BottomHeader";

const Search = ({ airports }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        className="w-full md:h-[680px]  flex flex-col bg-cover bg-top bg-no-repeat"
        style={{ backgroundImage: "url('/banner.png')" }}
      >
        <TopHeader open={open} />
        <div className={`bg-secondary md:mt-0 mt-2 ${open ? "pb-4" : "pb-0"}`}>
          <BottomHeader open={open} setOpen={setOpen} />
        </div>

        <div className="max-w-[1280px] mx-auto w-full md:flex hidden items-center justify-between md:px-10 px-5">
          <FormIntro />
        </div>

        {/* Search Form - Adjust top dynamically when `open` is true */}
        <div
          className={` relative ${
            open ? "top-[0px]" : "md:top-[60px] top-[-25px]"
          }`}
        >
          <SearchForm
            airports={airports}
            refer="/search"
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
      <div className="bg-[#3472CD]  w-full md:h-[101px] h-[30px] "></div>
    </div>
  );
};

export default Search;
