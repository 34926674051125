import React from 'react'

const title = ["Mr", "Miss", "Mrs", "Ms"];

export const people = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const UserDetails = ({ form, errors, setFormField, type }) => {
  return (
    <div className=" mx-auto p-6 border border-secondary rounded-lg shadow-md bg-white">
    <h2 className="md:text-[32px] text-[18px] font-semibold mb-4">Contact Details</h2>
    <hr className="mb-4 border-dashed border-primary" />
    <form>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="col-span-2">
          <label className="block text-sm font-medium mb-1">Title*</label>
          {/* <select className="w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none ">
            
            <option>Mr</option>
            <option>Mrs</option>
            <option>Miss</option>
          </select> */}
          <select
            name="title"
            value={form.title}
            onChange={(e) => setFormField("title", e.target.value)}
            className={`${
              //@ts-ignore
              errors?.some((error) => error.label === "title")
                ? `${
                    form.title.length
                      ? "border-[#70707069]"
                      : "border-[#FF0909]"
                  }`
                : null
            } w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none `}
          >
            {title.map((o, index) => (
              <option key={index}>{o}</option>
            ))}
          </select>
        </div>
        <div className="md:col-auto col-span-2">
          <label className="block text-sm font-medium mb-1">First Name*</label>
          <input
            type="text"
            placeholder="First name"
            name="firstName"
            value={form.first_name}
            onChange={(e) => setFormField("first_name", e.target.value)}
            className={`${
              //@ts-ignore
              errors?.some((error) => error.label === "first_name")
                ? `${
                    form.first_name.length
                      ? "border-[#70707069]"
                      : "border-[#FF0909]"
                  }`
                : null
            } w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none`}
          />
        </div>
        <div className="md:col-auto col-span-2">
          <label className="block text-sm font-medium mb-1">Last Name*</label>
          <input
            type="text"
            placeholder="Last name"
            name="lastName"
            value={form.last_name}
            onChange={(e) => setFormField("last_name", e.target.value)}
            className={`${
              //@ts-ignore
              errors?.some((error) => error.label === "last_name")
                ? `${
                    form.last_name.length
                      ? "border-[#70707069]"
                      : "border-[#FF0909]"
                  }`
                : null
            } w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none`}
          />
        </div>
        <div className="md:col-auto col-span-2">
          <label className="block text-sm font-medium mb-1">Email*</label>
          <input
            type="text"
            placeholder="Enter your email"
            name="email"
            value={form.email}
            onChange={(e) => setFormField("email", e.target.value)}
            className={`${
              //@ts-ignore
              errors?.some((error) => error.label === "email")
                ? `${
                    form.email.length
                      ? "border-[#70707069]"
                      : "border-[#FF0909]"
                  }`
                : null
            } w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none`}
          />
        </div>
        <div className="md:col-auto col-span-2">
          <label className="block text-sm font-medium mb-1">Mobile Number*</label>
          <input
            type="text"
            placeholder="Enter your mobile number"
            name="contact_no"
            value={form.contact_no}
            onChange={(e) => setFormField("contact_no", e.target.value)}
            maxLength={15}
            className={`${
              //@ts-ignore
              errors?.some((error) => error.label === "contact_no")
                ? `${
                    form.contact_no.length
                      ? "border-[#70707069]"
                      : "border-[#FF0909]"
                  }`
                : null
            } w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none`}
          />
        </div>
        {type == "Park And Ride" &&  <div className="col-span-2">
          <label className="block text-sm font-medium mb-1">People*</label>
       
          <select
            name="people"
            value={form.no_of_peoples}
            onChange={(e) => setFormField("no_of_peoples", e.target.value)}
            className="w-full p-2 border border-secondary rounded-md bg-[#F6F9FF] focus:outline-none"
          >
            {people?.map((p, index) => {
              return (
                <option
                  className="sm:text-base text-sm capitalize"
                  key={index}
                  value={p}
                >
                  {p}
                </option>
              );
            })}
          </select>
        </div>}
       
      </div>
    </form>
  </div>
  )
}

export default UserDetails