import moment from "moment";

export default function initializeArrivalDate(arrival) {
  if (arrival.length) {
    return arrival;
  }
  return moment()
    .add(7, "days")
    .set("hours", 12)
    .set("minutes", 0)
    .format("yyyy-MM-DD HH:mm");
}
