import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-[1280px]  mx-auto p-6 leading-8">
      <h1 className="md:text-4xl text-2xl text-center font-bold text-secondary mb-4">Terms and Conditions</h1>
      
      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">1. BOOKINGS</h2>
      <ol className="list-decimal list-inside text-[15px] leading-8 md:text-start text-justify ">
        <li>1.1-Bookings through our website or Consolidators are deemed to be made when final confirmation of the booking has been sent via e-mail or over the phone by one of our representatives. This also means you have agreed to our terms and conditions and the booking you make is valid only for the times, dates, price, and terminal specified in your booking and quotes are valid at the time of booking only.</li>
        <li>1.2-We advise you to arrive at car park at least 30 minutes prior to check in time with your airline. We cannot be held liable if bad traffic causes you to miss your flight or any other loss. We always encourage our customers to communicate with us of any delays.</li>
        <li>1.3-On your return and when you are to collect your car it is important that you call when you are about 30 minutes away from collecting your car and keep us informed of any delays. You will be required to pay the airports standard fee in order to exit the car park.</li>
        <li>1.4-We do our best to ensure that collections and deliveries of the vehicle are made at the requested times. We do not accept any responsibility for delays of its service, caused as a result of circumstances beyond our control, such as traffic congestion, delayed flights, road accidents, security alerts, severe weather conditions, luggage delays and immigration delays.</li>
        <li>1.5-In case if you do not return as expected you may keep us informed and we will only keep the vehicle for 30 days from the date of exit in case if you fail to inform us. We will deem the vehicle to have been abandoned in such circumstances, providing we serve not less than 30 days notice. We shall be entitled to remove and sell the vehicle and deduct from the proceeds both our reasonable handling expenses and any unpaid sums for days in which the car has been in the car park calculated at the tariff of your chosen service. We will send the balance amount by cheque.</li>
        <li>1.6-All reservations are non-transferable either from you to someone else or between different car parks.</li>
        <li>1.7-The booking you make is valid only for the times, dates, price and terminal specified in your booking and Quotes are valid at the time of booking only.</li>
        <li>1.8-When you return to collect your car it is important that you call us when you are almost ready to collect your car. you will be required to pay the airports standard fee in order to exit the car park.</li>
        <li>1.9-We do our best to ensure that collections and deliveries of the vehicle are made at the requested times. We do not accept any responsibility for delays of its service, caused as a result of circumstances beyond our control, such as traffic congestion, delayed flights, road accidents, security alerts, severe weather conditions, luggage delays and immigration delays.</li>
        <li>1.10-All services are subject to availability.</li>
      </ol>

      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">2. PAYMENT</h2>
      <ol className="list-decimal list-inside text-[15px] leading-8 md:text-start text-justify">
        <li>2.1-Full payment to be made at the time of the purchase. You must ensure that the credit or debit card you are using is your own (if it is a third party's you have their authorisation, to use their credit or debit card) and that sufficient funds are available to cover the cost of the arrangements which you book with us.</li>
        <li>2.2-In case if you do not return on the expected date, increased duration of the stay will be debited from the clients account and payment will be collected prior to the return of the vehicle. Any extended days will be charged at a daily rate of £20.00. If your return time passes midnight of the already paid booking date, and your car needs to be delivered after midnight, an additional charge of £25.00 is applied.</li>
      </ol>

      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">3. CANCELLATIONS</h2>
      <ol className="list-decimal list-inside text-[15px] leading-8 md:text-start text-justify">
        <li>3.1-We understand that your plans and intentions may change as per the circumstances. Our request is that you allow us enough time; to be specific 24 hours before the vehicle drop off day and time, so that we can amend your booking internally and allow the space to become available for others. If you fail to inform us 24 hours prior to our engagement in providing you with valet parking service, full charges will still apply.</li>
        <li>3.2- Cancellations can be made directly over the phone or through our website contact us page, or you can forward your request via email to info@kangarooparking.co.uk in accordance with our notice period. Successfully cancelled bookings may carry an administrative charge of £20. Any customer wishing to curtail length of stay for a service once that service has commenced will be liable to pay the fee for the whole service booked. Any alterations made within 24 hours of departure and during the duration of stay will incur a charge of £10 for each and every amendment made. All amendments must be sent via email.</li>
      </ol>

      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">4. LIABILITIES</h2>
      <ol className="list-decimal list-inside text-[15px] leading-8 md:text-start text-justify">
        <li>4.1-Your vehicle must be taxed and comply with the Road Traffic Act 1988. This is deemed by us to be the case for the whole duration while the vehicle is in our possession. Any liabilities occurred by our Company as a result of the client's vehicle not complying with the Road Traffic Act, the Customer will be held responsible for all costs/liabilities incurred by the Company.</li>
        <li>4.2-We cannot accept liability for any mechanical, electrical or structural failure of any part of your vehicle including Windscreens, Glass, Tyres, Alarms, Immobilizers, Flat-Batteries, and Wheels etc. We will not be held responsible for any deterioration in the condition of the vehicle while in our keeping. We accept no liability for any faulty cars, alarm fobs, any valuables which are left on the key ring or inside the vehicle.</li>
        <li>4.3-We require customers to have a spare key to the vehicle which they take with them. Any claims for damages must be reported immediately to our staff on delivery of the car to the airport. We may not consider any claims after the vehicle being delivered to you or any delay in reporting that cannot be verified by our staff.</li>
        <li>4.4-In the event that the vehicle does not start due to a flat battery, we reserve the right to charge in attempting to start the vehicle. We will not be held responsible for any consequences that may result as a direct result of us having to jump-start your vehicle.</li>
        <li>4.5-In the event that your vehicle needs to be repaired as a result of our negligence, it must be carried out by our own approved organisation. It will be your responsibility to deliver and collect the car from the garage at your own cost. We will not authorise or agree for any works to be carried out by dealerships even in the event of the vehicle forgoing its warranty. We will not be offering any other vehicle as a substitute (courtesy vehicle) while your vehicle is in repair.</li>
        <li>4.6-Also the car parks are open-air surface compound hence we cannot accept liability for damage to the vehicles or other property arising from acts of God or natural causes/disasters or any causes beyond our control.</li>
        <li>4.7-We accept no liability if you lose your Return Receipt, and this is presented to the Company by a third party who uses it fraudulently to take the delivery of your vehicle.</li>
        <li>4.8-Once you have made the booking, the terms which apply are those which were on the website at the time of booking. Please do not assume that the terms which applied on one occasion when you booked a service from us will continue to apply when you book next.</li>
        <li>4.9-It is the Customer's prime responsibility to comply with the Road Traffic Act. The customer is responsible for any fines, liabilities and consequential loss we incur from a failure to do this. We reserve the right to refuse your vehicle on the day and no refund will be given.</li>
        <li>4.10-While the car is parked in our car parks, the vehicles are only insured for any damages that may happen through our error and any other incidents happen while it is parked any claims need to be referred to your own insurance as we are only covered from and to the airport. Also we do not pay any excess fee for the customers insurance.</li>
        <li>4.11-We will not be held responsible for any deterioration in the condition of the vehicle while in our keeping.</li>
        <li>4.12-We accept no liability for loss of car alarm fobs, house or any other keys or valuables which are left on the key ring or inside the vehicle.</li>
        <li>4.13-We require customers to have a spare key to the vehicle which they take with them.</li>
        <li>4.14-Any claims for damages must be reported immediately to our staff immediately on delivery of the car to the airport before leaving the airport car park.</li>
        <li>4.15-Any claims will not be considered if reported after the vehicle has been returned to your possession and there is a delay in reporting any claims that cannot be verified by our staff.</li>
        <li>4.16-In the event that the vehicle does not start due to a flat battery, we reserve the right to charge for our time in attempting to start the vehicle. We will not be held responsible for any consequences that may result as a direct or indirect result of us having to jump-start your vehicle.</li>
        <li>4.17-In the event that your vehicle needs to be repaired as a result of our negligence, it must be carried out by our own approved garage at Heathrow. It will be your responsibility to deliver and collect the car from the garage at your own cost. We will not authorise or agree for any works to be carried out by dealerships even in the event of the vehicle forgoing its warranty.</li>
        <li>4.18-During certain busy periods or lengthy periods of stay, your car may be parked in any one of our Secondary Compounds which could be up to 15 miles away (one way), depending on which terminal you have dropped your vehicle off. Please note the security level at these secondary compounds may not be at the same level as the main compound.</li>
        <li>4.19-We will not be offering any other vehicle as a substitute (courtesy vehicle) while your vehicle is in repair.</li>
        <li>4.20-We cannot guarantee the safety of the vehicle nor their contents. Also, the car parks are open-air surface compounds.</li>
        <li>4.21-We cannot accept liability for damage to the vehicles or other property arising from acts of God or natural causes/disasters or any causes beyond our control.</li>
        <li>4.22-We accept no liability if you lose your Return Receipt and this is presented to the Company by a third party who uses it fraudulently to take the delivery of your vehicle.</li>
        <li>4.23-Once you have made the booking, the terms which apply are those which were on the website at the time of booking. Please do not assume that the terms which applied on one occasion when you booked a service from us will continue to apply when you next book.</li>
        <li>4.24-We will not accept any liability for your vehicle if you opt for a car wash and any old dents and scratches become visible after valeting.</li>
        <li>4.25-The customer must assure that he/she is the owner of the car or has the power to deal with the car as if he/she were its owner.</li>
        <li>4.26-It is the Customer's prime responsibility to comply with the Road Traffic Act. The customer is responsible for any fines, liabilities and consequential loss we incur from a failure to do this. We reserve the right to refuse your vehicle on the day and no refund will be given.</li>
        <li>4.27-While the car is parked in our car parks, the vehicles are not insured and any incidents happen while it is parked any claims need to be referred to your own insurance as we are only covered from and to the airport.</li>
        <li>4.28-There is no refund entitled once you have used the service and returned earlier than the booking time.</li>
      </ol>

      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">5. CONTACTING US</h2>
      <p className='leading-8'>5.1-If you have any questions, concerns, or complaints regarding our service, we encourage you to contact us using the details below:</p>
      <p className="font-semibold">Email: <a href="mailto:info@kangarooparking.co.uk" className="text-blue-500">info@kangarooparking.co.uk</a></p>
      <p className='leading-8'>5.2-Any incidents/issues raised whilst picking or dropping your vehicle need to be made apparent to a member of staff which will be reported/logged back to the Duty Manager. No acceptance of liability can be made until the matter is thoroughly investigated.</p>

      <h2 className="md:text-2xl text-[18px] font-semibold text-secondary mt-6">6. CHANGING THE CONDITIONS</h2>
      <p className='leading-8'>6.1-These conditions will remain in force unless the change is made in writing directly with ourselves and with our written permission.</p>
    </div>
  );
};

export default TermsAndConditions;