import React from 'react'
import PrivacyPolicy from '../containers/PrivacyPolicy/PrivacyPolicy'
import Seo from '../common/Components/Seo/Seo'

const PrivacyPolicyPage = () => {
  return (
    <div>
          <Seo
           seo={{
             title:
               "Privacy Policy Page | Kangaroo Parking Heathrow ",
            
           }}/>
    <PrivacyPolicy/>
    </div>
  )
}

export default PrivacyPolicyPage