import React from "react";
import SearchForm from "../SearchForm/SearchForm";

const Change = ({ setOpen, open, refer, airports }) => {
  return (
    <div>
     
        <SearchForm
          airports={airports}
          open={open}
          setOpen={setOpen}
          refer={refer}
        />
  
    </div>
  );
};

export default Change;
