import React, { useEffect, useMemo, useState } from "react";
// import Seo from "../common/Components/Seo/Seo";
import BookingConfirm from "../containers/BookingConfirm/BookingConfirm";
import { nextPage } from "../common/config/nextPage";
import Cookies from "js-cookie";
import Seo from "../common/Components/Seo/Seo";

const Success = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (typeof window.sessionStorage !== "undefined") {
      sessionStorage.removeItem("detail");
    }
  }, []);

  // useMemo(() => {
  //   if (count > 0) {
  //     sessionStorage.setItem("count", JSON.stringify(count));
  //   }
  // }, [count]);

  useEffect(() => {
    let value = Cookies.get("customCid");
    if (value) {
      Cookies.remove("customCid");
    }
  }, []);

  return (
    <>
      <Seo
        seo={{
          title: "Success Page | Kangaroo Parking Heathrow",
        }}
      />

      <BookingConfirm />
    </>
  );
};

export default Success;
