import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = ({ seo }) => {
  return (
    <>
      <Helmet>
        <title>{seo?.title}</title>
        <meta name="keywords" content={seo?.keywords} />
        <meta name="description" content={seo?.description} />
      </Helmet>
    </>
  );
};

export default Seo;
