import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import UpdateSearch from "./UpdateSearch";

const ModalChange = ({ setOpen, open, refer, airports }) => {
  return (
    <div className="rounded-lg">
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        classNames={{
          modal: " md:w-[35%] w-[90%] mx-auto  ",
        }}
        styles={{
          modal: {
            margin: "0px auto",
            // width: "90%",
            // maxWidth: "600px", 
            closeButton: "custom-close-btn", 
            // overflow:"scroll"
           minHeight:"450px"
          },
        }}
        blockScroll
      >
        <div className="pt-10">
        <UpdateSearch airports={airports} open={open} setOpen={setOpen} refer={refer} />
        </div>
      </Modal>
    </div>
  );
};

export default ModalChange;
