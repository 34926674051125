import React from 'react'
import Accordions from '../containers/FAQs/Accordians'
import Seo from '../common/Components/Seo/Seo'

const Faqs = () => {
  return (
    <div>
          <Seo
           seo={{
             title:
               "Faqs Page | Kangaroo Parking Heathrow ",
            
           }}/>
        <Accordions/>
    </div>
  )
}

export default Faqs