import { useState, useEffect } from "react";
import { HiMenu, HiX } from "react-icons/hi";

const OtherHeader = ({isOpen, setIsOpen}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [activePath, setActivePath] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => setActivePath(window.location.pathname);
    window.addEventListener("popstate", handleRouteChange);
    return () => window.removeEventListener("popstate", handleRouteChange);
  }, []);

  const navItems = ["Home", "FAQs", "Contact Us"];

  return (
   <div className="">
     <nav className="px-6 max-w-[1280px] mx-auto w-full ">
     <div className="bg-white h-[1px] w-full relative top-[0px]">
     </div>
 
       <div className="flex justify-between items-center">
      
        {/* Logo */}
        <a href="/" onClick={() => setActivePath("/")}>
          <img src="/white-logo.png" alt="Park Plus Logo" className="h-[70px] md:h-[131px] md:w-[228px] w-auto my-2" />
        </a>

        <button
          className="md:hidden text-2xl text-gray-700"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <HiX className="text-primary" /> : <HiMenu className="text-primary" />}
        </button>

        {/* Desktop Navigation */}
        <div className="hidden md:flex flex-[0.3] justify-between space-x-6 text-white  mt-[3px] ">
          {navItems.map((item, index) => {
            const path = item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, "-")}`;
            return (
              <a
                key={index}
                href={path}
                className={`font-normal hover:bg-[#7d92f1] hover:border-b-2 hover:border-white h-[100px] px-2 py-1 flex justify-center items-center relative top-[-25px]  ${
                  activePath === path ? "text-white border-b-2 border-white bg-[#7d92f1]"  : ""
                }`}
                onClick={() => setActivePath(path)}
              >
                {item}
              </a>
            );
          })}
        </div>
      </div>


      {/* Mobile Dropdown */}
      {isOpen && (
        <div className="md:hidden my-4 bg-white shadow-lg rounded-md p-3 ">
          {navItems.map((item, index) => {
            const path = item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, "-")}`;
            return (
              <a
                key={index}
                href={path}
                className={`block text-black py-2 font-medium hover:text-black ${
                  activePath === path ? "text-primary font-bold" : ""
                }`}
                onClick={() => {
                  setIsOpen(false);
                  setActivePath(path);
                }}
              >
                {item}
              </a>
            );
          })}
        </div>
      )}
    </nav>
   </div>
  );
};

export default OtherHeader;
