import React from 'react'
import ContactUs from '../containers/ContactUs/ContactUs'
import Seo from '../common/Components/Seo/Seo'

const ContactPage = () => {
  return (
    <div>
          <Seo
           seo={{
             title:
               "Contact Us Page | Kangaroo Parking Heathrow ",
            
           }}/>
        <ContactUs/>
    </div>
  )
}

export default ContactPage