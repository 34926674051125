import React from "react";
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./common/Components/Header/Header";
import Footer from "./common/Components/Footer/Footer";
import AirplaneAnimation from "./common/Components/AnimatedPlane/animatedPlane";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Booking from "./pages/Booking";
import Success from "./pages/Success";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ContactPage from "./pages/ContactUs";
import Faqs from "./pages/Faqs";
import { allRoutes } from "../src/common/libs/allRoutes";
import Payment from "./pages/Payment";

const routeComponents = {
  "/search": Search,
  "/booking": Booking,
  "/success": Success,
  "/contact-us": ContactPage,
  "/privacy-policy": PrivacyPolicyPage,
  "/terms-and-conditions": TermsAndCondition,
  "/faqs": Faqs,
  "/payment": Payment
};

const App = () => {
  return (
    <HelmetProvider>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-1">
          <Routes>
            {allRoutes.map((route) => {
              const Component = routeComponents[route] || Home;
              return <Route key={route} path={route} element={<Component />} />;
            })}
          </Routes>
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default App;
