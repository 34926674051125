import React from "react";

const TermsCondition = () => {
  return (
    <div>
      Terms & conditions Will be provided on request.
    </div>
  );
};

export default TermsCondition;
