export const allRoutes = [
  "/",
  "/search",
  "/booking",
  "/payment",
  "/success",
  "/terms-and-conditions",
  "/privacy-policy",
  "/refund-policy",
  "/contact-us",
  "/reviews",
  "/about-us",
  "/faqs",
  "/gatwick-airport-parking",
  "/manchester-airport-parking",
  "/stansted-airport-parking",
  "/birmingham-airport-parking",
  "/heathrow-airport-parking",
  "/bristol-airport-parking",
  "/luton-airport-parking",
  "/liverpool-airport-parking",
  "/edinburgh-airport-parking",
  "/glasgow-airport-parking",
  "/belfast-city-airport-parking",
  "/belfast-international-airport",
  "/southampton-airport-parking",
  "/newcastle-airport-parking",
  "/southend-airport-parking",
  "/london-city-airport-parking",
  "/leeds-bradford-airport-parking",
  "/east-midlands-airport-parking",
  "/cardiff-airport-parking",
  "/aberdeen-airport-parking",
];
