import React from "react";

const Filter = ({ count, handleSort, handleFilter }) => {
  return (
    <div className="max-container max-w-[1280px] mx-auto py-4  flex justify-between items-center md:px-10 px-5">
      <h2 className="text-lg font-semibold text-secondary">
        Found <span className="text-primary">{count || 0}</span> Car Parks 
      </h2>

      {/* <div className="flex items-center space-x-4">
        <span className="text-secondary text-sm font-medium">Sort by:</span>

        <select
          onChange={handleFilter}
          className="border rounded-md px-4 py-2 text-sm text-secondary focus:outline-none "
        >
          <option disabled selected>
            Services Type
          </option>
          <option value="all">All</option>
          <option value="Park And Ride">Park and Ride</option>
          <option value="Meet And Greet">Meet and Greet</option>
        </select>

        <select
          onChange={handleSort}
          className="border rounded-md px-4 py-2 text-sm text-secondary focus:outline-none "
        >
          <option disabled selected>
            Prices
          </option>
          <option value="0">Price low to high</option>
          <option value="1">Price high to low</option>
        </select>
      </div> */}
    </div>
  );
};

export default Filter;
