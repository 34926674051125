import React, { useState, useEffect } from "react";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import apiKey from "../../../common/config/apiKey";
import apiCall from "../../../common/config/apiCall";
import { useSearchParams, useLocation } from "react-router-dom";
import useBookingForm from "../../../hooks/useBookingForm";
import calculateTotalPrice from "../../../common/libs/caclulateTotalPrice";
import validateBookingForm from "../../../common/libs/validateBookingForm";
import UserDetails from "./UserDetails";
import VehicleDetails from "./VehicleDetails";
import StripePay from "./StripePay";
import Cookies from "js-cookie";
import TravelDetails from "./TravelDetails";

const BookingForm = ({
  product,
  airports,
  site,
  cancellation,
  setCancellation,
  setSMS,
  vehicles,
  setVehicles,
  sms,
  singleAirport,
}) => {
  const stripePromise = site?.stripe ? loadStripe(site?.stripe?.key) : null;
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getReference = params.get("reference_no");
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getPromocode = params.get("promocode");
  const getAirport = params.get("airport");

  const [state, setState] = useState("initial");
  const [responseError, setResponseError] = useState("");
  const [syncBookingState, setSyncBookingState] = useState("initial");
  const [status, setStatus] = useState("");
  const [reference_no, setReference_no] = useState(
    getReference ? [getReference] : ""
  );
  const [multi_mode_reference_no, setMulti_mode_reference_no] = useState(
    getReference ? `MM-${getReference}` : ""
  );
  const [supplierCost, setSupplierCost] = useState("");
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setFormField, setForm] = useBookingForm();
  const [cookie, setCookie] = useState(null);
  const [error, setAgreeError] = useState({});

  // Stripe
  const [clientSecret, setClientSecret] = useState("");
  const [stripePaymentIntent, setStripePaymentIntent] = useState("");

  // TRUSTPAYMENT
  // const [trustPaymentCode, setTrustPaymentCode] = useState("");
  // const [trustPaymentTimeStamp, setTrustPaymentTimeStamp] = useState("");

  // REVOLUTE PAYMENT
  // const [revolutepaymentData, setRevolutePaymentData] = useState();

  // TALKSOLUTIONS
  // const [talksolutionCode, setTalksolutionCode] = useState("");

  let price = product?.payment
    ? parseFloat(product?.payment?.amount) * vehicles.length
    : parseFloat(product?.price) * vehicles.length;
  const total = calculateTotalPrice(product, price, cancellation, sms);
  // TAKEPAYMENTDATA
  // const [takepaymentData, setTakepaymentData] = useState();
  // WORLDPAY
  // const [worldpayState, setWorldpayState] = useState("initial");
  // const [worldpaySession, setWorldpaySession] = useState("");
  // const [worldpayRedirectUrl, setWorldpayRedirectUrl] = useState("");
  // PAYPAL SETTING
  const [isForm, setIsForm] = useState(false);
  const [isVehicle, setIsVehicle] = useState(false);

  let updateAirports = [];

  if (airports?.length) {
    airports?.map((airport) => {
      updateAirports.push({
        value: airport.code,
        label: airport.name,
        terminal: airport.terminal,
      });
    });
  }

  let findIndex = updateAirports.findIndex((a) => a.value === getAirport);

  const intentRef = React.useRef(false);

  const fetchData = async () => {
    let response = await apiCall(
      "post",
      `/stripe/paymentIntent/create`,
      {
        key: apiKey,
        amount: total,
        api_tag: product?.api_tag,
      },
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      pathname
    );

    if (response?.data?.client_secret) {
      setClientSecret(response?.data?.client_secret);
      setStripePaymentIntent(response?.data?.payment_intent);
    } else {
      setClientSecret("");
      setStripePaymentIntent("");
    }
  };

  React.useEffect(() => {
    if (intentRef.current) return;
    intentRef.current = true;
    if (site?.stripe) {
      if (!stripePaymentIntent) {
        fetchData();
      }
    }
  }, [stripePaymentIntent]);

  useEffect(() => {
    vehicles?.map((vehicle) => {
      const { make, model, color, reg_no } = vehicle;
      if (make.length && model.length && color.length && reg_no.length) {
        setIsVehicle(true);
      }
    });
    if (
      form.title.length &&
      form.first_name.length &&
      form.last_name.length &&
      form.contact_no.length &&
      form.email.length &&
      syncBookingState === "initial"
    ) {
      setIsForm(true);
      const delayInterval = setTimeout(() => {
        handleSyncBooking();
      }, 3000);
      return () => clearTimeout(delayInterval);
    }
  }, [form, vehicles, syncBookingState]);

  useEffect(() => {
    let quoteAmount = parseFloat(product.price);
    // @ts-ignore
    setSupplierCost(vehicles?.length * quoteAmount);
  }, [vehicles, product.price]);

  useEffect(() => {
    if (typeof window.sessionStorage !== "undefined") {
      const getDetails = JSON.parse(sessionStorage.getItem("detail"));
      if (getDetails) {
        let obj = { ...getDetails };

        if (Object.values(obj)?.length > 0) {
          setForm(obj);
          setVehicles(getDetails?.vehicles);
          setSMS(getDetails?.sms_confirmation == 1 ? true : false);
          setCancellation(getDetails?.cancellation_status == 1 ? true : false);
        }
      }
    }
  }, []);

  useEffect(() => {
    let value = Cookies.get("customCid");
    if (value) {
      setCookie(value);
    }
  }, []);

  const handleSyncBooking = async (paymentType = "") => {
    setSyncBookingState("triggered");
    setStatus("");
    setResponseError("");

    //@ts-ignore
    form.vehicles = vehicles;
    let details = {
      key: apiKey,
      api_tag: product.api_tag ? product.api_tag : "",
      option_id: product?.option_id ? JSON.stringify(product?.option_id) : "",
      search_id: product?.search_id ? product?.search_id : "",
      multi_mode_reference_no,
      reference_no,
      status: "0",
      departure: moment(getDeparture).format("DD-MM-yyyy HH:mm"),
      arrival: moment(getArrival).format("DD-MM-yyyy HH:mm"),
      sku: product?.sku,
      amount: product?.price,
      ...form,
      discount_amount: product.discount || "0.00",
      discount_code: getPromocode?.toString() || "",
      admin_charges: product.admin_charges,
      cancellation_status: cancellation ? "1" : "0",
      sms_confirmation: sms ? "1" : "0",
      icampaign: cookie,
    };

    try {
      if (multi_mode_reference_no.length === 0 && reference_no.length === 0) {
        //@ts-ignore

        const storeResponse = await apiCall(
          "post",
          "/bookings/store",
          details,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          pathname
        );
        if (
          storeResponse.data?.reference_no &&
          storeResponse.data?.multi_mode_reference_no
        ) {
          if (typeof sessionStorage !== "undefined") {
            sessionStorage.setItem(
              "detail",
              JSON.stringify({
                ...details,
                reference_no: storeResponse.data?.reference_no,
                multi_mode_reference_no:
                  storeResponse.data?.multi_mode_reference_no,
              })
            );
          }
          //Stripe
          if (site?.stripe) {
            await apiCall(
              "post",
              "/stripe/paymentIntent/update",
              {
                key: apiKey,
                payment_intent: stripePaymentIntent,
                multi_mode_reference_no:
                  storeResponse.data?.multi_mode_reference_no,
                reference_no: storeResponse.data?.reference_no,
                amount: total,
                api_tag: product?.api_tag,
              },
              {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              pathname
            );
          }
          setMulti_mode_reference_no(
            storeResponse.data?.multi_mode_reference_no
          );
          //@ts-ignore
          setReference_no(storeResponse.data?.reference_no);

          return storeResponse?.data;
        }
      } else {
        let updateResponse;
        //@ts-ignore
        if (product?.customer) {
          updateResponse = await apiCall(
            "post",
            "/bookings/update",
            {
              key: apiKey,
              multi_mode_reference_no,
              status: "0",
              departure: `${moment(product?.departure_date).format(
                "DD-MM-yyyy"
              )} ${product?.departure_time.slice(0, -3)}`,
              arrival: `${moment(product?.arrival_date).format(
                "DD-MM-yyyy"
              )} ${product?.arrival_time.slice(0, -3)}`,
              sku: product?.sku,
              title: product?.customer?.title,
              first_name: product?.customer?.first_name,
              last_name: product?.customer?.last_name,
              email: product?.customer?.email,
              contact_no: product?.customer?.contact_no,
              no_of_peoples: product?.customer?.no_of_peoples,
              address: "",
              postcode: "",
              //@ts-ignore
              vehicles,
              departure_terminal: product?.departure_terminal,
              departure_flight_no: product?.departure_flight_no,
              arrival_terminal: product?.arrival_terminal,
              arrival_flight_no: product?.arrival_flight_no,
              amount: product?.payment?.amount,
              discount_amount: product?.payment?.discount || "0.00",
              discount_code: product?.payment?.discount_code?.toString() || "",
              admin_charges: product?.payment?.admin_charges,
              cancellation_status: `${product?.cancellation_status}`,
              sms_confirmation: `${product?.sms_confirmation}`,
              option_id: product?.option_id
                ? JSON.stringify(product?.option_id)
                : "",
              search_id: product?.search_id ? product?.search_id : "",
              api_tag: product?.api_tag,
            },
            {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            pathname
          );
        } else {
          updateResponse = await apiCall(
            "post",
            "/bookings/update",
            {
              key: apiKey,
              multi_mode_reference_no,
              status: "0",
              departure: moment(getDeparture).format("DD-MM-yyyy HH:mm"),
              arrival: moment(getArrival).format("DD-MM-yyyy HH:mm"),
              sku: product?.sku,
              ...form,
              amount: product?.price,
              discount_amount: product.discount || "0.00",
              discount_code: getPromocode?.toString() || "",
              admin_charges: product?.admin_charges,
              cancellation_status: cancellation ? "1" : "0",
              sms_confirmation: sms ? "1" : "0",
              option_id: product?.option_id
                ? JSON.stringify(product?.option_id)
                : "",
              search_id: product?.search_id ? product?.search_id : "",
              api_tag: product?.api_tag,
              icampaign: cookie,
            },
            {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            pathname
          );
        }
        if (
          updateResponse.data?.reference_no &&
          updateResponse.data?.multi_mode_reference_no
        ) {
          //Stripe
          if (typeof window !== "undefined") {
            sessionStorage.setItem(
              "detail",
              JSON.stringify({
                ...details,
                reference_no: updateResponse.data?.reference_no,
                multi_mode_reference_no:
                  updateResponse.data?.multi_mode_reference_no,
              })
            );
          }
          if (site?.stripe) {
            let updateIntentData = await apiCall(
              "post",
              "/stripe/paymentIntent/update",
              {
                key: apiKey,
                payment_intent: stripePaymentIntent,
                multi_mode_reference_no:
                  updateResponse.data?.multi_mode_reference_no,
                reference_no: updateResponse.data?.reference_no,
                amount: total,
                api_tag: product?.api_tag,
              },
              {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              pathname
            );

            if (updateIntentData?.data?.client_secret) {
              apiCall(
                "post",
                "/bookings/updateIntent",
                {
                  key: apiKey,
                  payment_token: updateIntentData?.data?.payment_intent,
                  multi_mode_reference_no:
                    updateResponse.data?.multi_mode_reference_no,
                },
                {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                pathname
              );
            }
          }

          setMulti_mode_reference_no(
            updateResponse.data?.multi_mode_reference_no
          );
          setReference_no(updateResponse.data?.reference_no);

          return updateResponse?.data;
        } else {
          setResponseError(updateResponse.message);
          return;
        }
        // }
      }
      setState("success");
    } catch (error) {
      setState("error");
      setStatus(error.message);
    }
  };

  const handleValidate = () => {
    let errorResponse = validateBookingForm(
      product?.customer
        ? {
            title: product?.customer?.title,
            first_name: product?.customer?.first_name,
            last_name: product?.customer?.last_name,
            email: product?.customer?.email,
            contact_no: product?.customer?.contact_no,
          }
        : form,
      vehicles,
      agree
    );
    setErrors(errorResponse.errors);
    // setValidationErrorState(errorResponse?.state);

    if (errorResponse?.errors.length > 0 && errorResponse?.state === "error") {
      errorResponse?.errors.map((error) => {
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
      return false;
    } else {
      return true;
    }
  };

  const handleAddVehicle = (e) => {
    e.preventDefault();
    setVehicles((vehicles) => [
      ...vehicles,
      {
        model: "TBC",
        color: "TBC",
        make: "TBC",
        reg_no: "TBC",
      },
    ]);
  };

  const handleRemoveVehicle = (e, index) => {
    e.preventDefault();
    let vehicleArray = [...vehicles];

    vehicleArray.splice(index, 1);
    setVehicles(vehicleArray);
  };

  // @ts-ignore
  const handleChange = (e, index) => {
    let vehicleArray = [...vehicles];
    let vehicleObj = vehicleArray[index];
    // @ts-ignore
    vehicleObj[e.target.name] = e.target.value;
    setVehicles(vehicleArray);
  };

  return (
    <>
      <div className="booking-form-container flex-[2_1_0%] lg:mr-[30px] mr-0">
        {status ? (
          <div
            style={{
              background: "#f6f6f6",
              padding: "20px",
              textAlign: "center",
              borderRadius: "5px",
              color: "red",
              border: "1px solid red",
              marginBottom: "30px",
            }}
          >
            <strong>{status?.toUpperCase()}</strong>
          </div>
        ) : null}
        {pathname != "/payment" && (
          <div className="shadow-box">
            <UserDetails
              form={form}
              errors={errors}
              setFormField={setFormField}
              type={product?.type}
            />
            <TravelDetails
              form={form}
              setFormField={setFormField}
              index={findIndex}
              updateAirports={updateAirports}
            />
          </div>
        )}
        {pathname != "/payment" &&
          vehicles?.map((vehicle, index) => {
            return (
              <>
                <VehicleDetails
                  key={index}
                  id={index}
                  index={index}
                  product={product}
                  vehicles={vehicles}
                  setVehicles={setVehicles}
                  handleChange={(e) => handleChange(e, index)}
                  errors={errors}
                  handleAddVehicle={handleAddVehicle}
                  handleRemoveVehicle={handleRemoveVehicle}
                />
              </>
            );
          })}

        {pathname != "/payment" && (
          <div className="bg-secondary text-white rounded-lg p-4 flex md:flex-row flex-col justify-between items-start md:gap-0 gap-2 mt-5 mx-auto">
            {/* SMS Confirmation */}
            <label
              className="flex flex-1 items-start gap-3 cursor-pointer"
              onClick={() => setSMS(!sms)}
            >
              <div className="flex-[0.1]">
                <div
                  className={`w-5 h-5 flex items-center bg-primary justify-center rounded-md border-2 ${
                    sms ? "bg-gray-900 border-primary" : "border-primary"
                  }`}
                >
                  {sms && <span className="text-white font-bold">✓</span>}
                </div>
              </div>
              <div className="flex-[1.9]">
                <p className="font-bold">Text Message Confirmation</p>
                <p className="text-sm">(UK mobiles only) £0.50</p>
              </div>
            </label>

            <label
              className="flex flex-1 items-start gap-3 cursor-pointer"
              onClick={() => setCancellation(!cancellation)}
            >
              <div className="flex-[0.1]">
                <div
                  className={`w-5 h-5 flex items-center justify-center bg-primary rounded-md border-2 ${
                    sms ? "bg-gray-900 border-primary" : "border-primary"
                  }`}
                >
                  {cancellation && (
                    <span className="text-white font-bold">✓</span>
                  )}
                </div>
              </div>
              <div className="flex-[1.9]">
                <p className="font-bold">Cancellation Cover</p>
                <p className="text-sm">
                  Protect your booking if you need to cancel or amend in future
                  £2.00
                </p>
              </div>
            </label>
          </div>
        )}

        <div className="p-6 border border-secondary rounded-lg shadow-md mt-5">
          {clientSecret ? (
            <StripePay
              multiModeReference={multi_mode_reference_no}
              reference_no={reference_no}
              handleSyncBooking={handleSyncBooking}
              total={total}
              clientSecret={clientSecret}
              stripePaymentIntent={stripePaymentIntent}
              stripePromise={stripePromise}
              firstName={form?.first_name}
              lastName={form?.last_name}
              email={form?.email}
              mobile={form?.contact_no}
              airport={
                singleAirport
                  ? singleAirport?.label
                  : updateAirports[findIndex].label
              }
              discountCode={getPromocode}
              handleValidate={handleValidate}
              responseError={responseError}
              service={product?.type}
              supplierCost={parseFloat(supplierCost) * 0.25}
              api_tag={product?.api_tag}
              isVehicle={isVehicle}
              product={product}
              setAgree={setAgree}
              agree={agree}
              error={error}
            />
          ) : null}

          {status ? (
            <div
              style={{
                background: "#f6f6f6",
                padding: "20px",
                textAlign: "center",
                borderRadius: "5px",
                color: "red",
                border: "1px solid red",
                marginTop: "30px",
              }}
            >
              <strong>{status?.toUpperCase()}</strong>
            </div>
          ) : null}
        </div>
        {/* <div className="lg-block">
          <Features>
            <ul>
              <div className="list-item">
                <span className="icon">
                  <Image src={secure} alt="secure" />
                </span>
                <p>Secure payment</p>
              </div>
              <div className="list-item">
                <span className="icon">
                  <Image src={check} alt="check" />
                </span>
                <p>
                  All parking lots are carefully <span>inspected</span>
                </p>
              </div>
              <div className="list-item">
                <span className="icon">
                  <Image src={tag} alt="tag" />
                </span>
                <p>
                  Over <span>2 million</span> customers globally
                </p>
              </div>
              <div className="list-item">
                <span className="icon">
                  <Image src={timer} alt="timer" />
                </span>
                <p>
                  <span>Cancel for free</span> up to 24 hours in advance
                </p>
              </div>
            </ul>
            <div className="asking-question">
              <div className="faqs-link">
                <Link href="/faqs">Do you have any questions?</Link>
              </div>
            </div>
            <div className="contact-us">
              <p>Contact our customer service</p>
              <p>5 days a week.</p>
              <p>
                <Link href="/contact">Email</Link>
                or call us
              </p>
            </div>
          </Features>
        </div> */}
      </div>
    </>
  );
};

export default BookingForm;
