export default function Footer() {
  return (
    <footer className="bg-cover text-white" style={{ backgroundImage: "url('/bg-footer.png')" }}>
      {/* Logo Section */}
      <div className="flex flex-col items-center py-6">
      <a href="/">
      <img
          src="/footer-logo.png" 
          alt="Kangaroo Parking"
          className="w-[274px] h-[157px]"
        />
      </a>
      </div>

      {/* Contact Section */}
      <div className="relative px-10">
        {/* Dashed Line */}
        <div className="border-t border-dashed border-[#FDC938] w-full"></div>

        <div className="flex md:flex-row flex-col justify-center items-center gap-12 py-6">
          {/* Phone Section */}
          <div className="flex flex-col items-center justify-center gap-y-5 text-center md:bg-cover bg-contain bg-no-repeat bg-center md:h-[200px] h-full w-[290px] " style={{ backgroundImage: "url('/bg-phone.png')" }}>
            <div className="text-2xl">
            <img
              alt="phone"
              src={require("../../images/footer-phone.png")}

              className="w-[38px] h-[38px]"
            />
            </div>
            <p className="font-semibold md:text-[18px] text-[16px]">Phone</p>
            <a href="tel:07413 818425" className="text-[14px] font-normal">07413 818425</a>
          </div>

          {/* Divider */}
          <div className="w-[1px] h-[200px] bg-white md:block hidden"></div>

          {/* Email Section */}
          <div className="flex flex-col items-center justify-center gap-y-5 text-center md:bg-cover bg-contain bg-no-repeat bg-center md:h-[200px]  h-full w-[290px]" style={{ backgroundImage: "url('/bg-email.png')" }}>
            <div className="text-2xl "          >
            <img
              alt="phone"
              src={require("../../images/footer-email.png")}

              className="w-[41px] h-[28px]"
            />
            </div>
            <p className="font-semibold md:text-[18px] text-[16px]">Email</p>
            <a   href="mailto:info@parking4you.co.uk" className="text-sm">info@kangarooparking.co.uk</a>
          </div>
        </div>

        {/* Dashed Line */}
        <div className="border-t border-dashed  leading-9 border-[#FDC938] w-full"></div>
      </div>

      {/* Navigation Links */}
  {/* Navigation Links */}
<div className="flex md:flex-row flex-col items-center font-normal justify-center gap-6 text-[16px] py-4">
  <a href="/" className="hover:text-[#FDC938] md:border-r-[1px] border-r-0 border-white pr-3">Home</a>
  <a href="/terms-and-conditions" className="hover:text-[#FDC938] md:border-r-[1px] border-r-0 border-white pr-3">Terms and Conditions</a>
  <a href="/privacy-policy" className="hover:text-[#FDC938] md:border-r-[1px] border-r-0 border-white pr-3">Privacy Policy</a>
  <a href="/faqs" className="hover:text-[#FDC938] md:border-r-[1px] border-r-0 border-white pr-3">FAQs</a>
  <a href="/contact-us" className="hover:text-[#FDC938] pr-3">Contact Us</a>
</div>


      {/* Copyright Section */}
      <div className="bg-gradient-to-b from-[#1E6CE1] to-[#236DDA] py-4 text-center text-sm px-3" style={{}}>
        Copyright 2025 – <span className="text-[#FDC938]">Kangaroo Parking</span>{" "}
        Developed by <span className="font-semibold">Binary Ops Pvt Ltd.</span>
      </div>
    </footer>
  );
}
