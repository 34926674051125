import React from "react";
import { FaCar, FaFile, FaHome, FaPlane, FaStar } from "react-icons/fa";

const NavLinks = ({ content, setContent }) => {
  return (
    <div className="moreinfo-nav-container flex-1 md:w-auto w-full md:mb-0 mb-5 font-semibold">
    <div className="nav grid md:grid-cols-1 sm:grid-cols-3 grid-cols-1 sm:border-b-0 border-b-[1px] border-darkGray">
      <button
        className={`${
          content === "overview"
            ? "bg-primary text-white"
            : "bg-white text-primary"
        } md:text-base text-sm text-left w-full p-2`}
        onClick={() => setContent("overview")}
      >
        Overview
      </button>
      <button
        className={`${
          content === "dropoffprocedure"
            ? "bg-primary text-white"
            : "bg-white text-primary"
        } md:text-base text-sm text-left w-full p-2`}
        onClick={() => setContent("dropoffprocedure")}
      >
        Drop-off Procedure
      </button>
      <button
        className={`${
          content === "pickupprocedure"
            ? "bg-primary text-white"
            : "bg-white text-primary"
        } md:text-base text-sm text-left w-full p-2`}
        onClick={() => setContent("pickupprocedure")}
      >
        Pick-up Procedure
      </button>
      <button
        className={`${
          content === "reviews"
            ? "bg-primary text-white"
            : "bg-white text-primary"
        } md:text-base text-sm text-left w-full p-2`}
        onClick={() => setContent("reviews")}
      >
        Reviews
      </button>
      <button
        className={`${
          content === "termscondition"
            ? "bg-primary text-white"
            : "bg-white text-primary"
        } md:text-base text-sm text-left w-full p-2`}
        onClick={() => setContent("termscondition")}
       
      >
        Terms & Conditions
      </button>
    </div>
  </div>
  );
};

export default NavLinks;
