import apiUrl from "./apiUrl";
export default async function apiCall(
  method,
  path,
  data,
  header = {},
  route = "",
  site = "compare airport parking deals"
) {
  try {
    if (method === "get") {
      const response = await fetch(
        `${apiUrl}${path}`,
        header && {
          headers: header,
        }
      );
      const json = await response.json();
      if (json?.success) {
        return json;
      }
      return json;
    } else {
      const response = await fetch(`${apiUrl}${path}`, {
        method: "POST",
        headers: header ? header : {},
        body: JSON.stringify(data),
      });
      const json = await response.json();
      if (json?.success) {
        return json;
      }
      return json;
    }
  } catch (error) {
    if (error) {
      const response = await fetch(`${apiUrl}/bookingErrorLogs/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: `site=${site} route=${route} endpoint=${path}`,
          error_log: `${error}`,
        }),
      });
      const json = await response.json();

      return error;
    }
  }
}
