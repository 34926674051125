
import React from "react";
import SingleAccordion from "./SingleAccordian";

const Accordions = () => {
  return (
    <div className="accordions max-w-[1280px] mx-auto py-10 md:px-10 px-5">
      <div className="title text-center">
        <h1 className="lg:text-5xl md:text-4xl text-2xl text-secondary font-bold">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex flex-col items-center pt-10">
        <SingleAccordion
          title="Are the cars parked in safe and secure location?"
          description='Yes, the companies associated with "www.kangarooparking.co.uk" are approved and maintains the "car
              parking" compounds. During the bookings, we are here to aid you with the exclusive details through "MORE
              INFO".'
        />
        <SingleAccordion
          title="Do I trust your online payment procedures?"
          description="Yes, we have authorized procedures and carry out the transaction in full privacy. The entire details of your credit cards and payments are fully protected whilst you are using the service and we remove all the data from our server on the completion of booking procedure."
        />
        <SingleAccordion
          title="How do I amend my bookings?"
          description="All alterations and cancellations are obliged to be shared with the www.kangarooparking.co.uk All amendments will be based on the terms and condition of www.kangarooparking.co.uk The customers who desires to diminish or prolong the stays for their reserved service will be liable to follow the deductions and payments accordingly."
        />
        <SingleAccordion
          title="What is the procedure for making the complaints?"
          description='We take the feedback and complaints of our customers very seriously and respond them as early as possible. Please send your complaints using ""contact form" from contact us page and we will forward them to your chosen service provider or you can send them your complaint directly. The www.kangarooparking.co.uk is the booking agent and is liable for the payments and "car parking" booking procedures but cannot be held the responsible of complaints against any car loss, damages, service delays. A customer"s contract is directly with the car park and any issues that may arise should be dealt with the service provider directly.'
        />
        <SingleAccordion
          title="How do I cancel a booking?"
          description='Please check Terms and Conditions "Section-4" and "Section-5". All cancellation requests should be made in writing through our customer contactUs link or via email on info@kangarooparking.co.uk'
        />
        <SingleAccordion
          title="Who will be waiting at the Airport?"
          description="As per your arranged time, the service provider will arrange their representative to the Airport to collect your vehicle."
        />
        <SingleAccordion
          title="Can my return date be changed?"
          description="Yes, you can change your return date 24hrs prior to the departure date. Once you left your vehicle with the service provider then you must contact to the service provider with your new return date and time."
        />
        <SingleAccordion
          title="Can I make an account?"
          description="Regular business customers are offered accounts by Kangaroo Parking Heathrow. For more details, please Contact Us."
        />
        <SingleAccordion
          title="Can special requests be made?"
          description="Yes, you can make special requests and we will try and help you in every possible way. While booking online, specify your special requests. We value our customers and are always interested and eager to fulfill all your requirements."
        />
        <SingleAccordion
          title="I have a question not listed above, who do I contact?"
          description="Please email us using the contact us page or call us between 9:00AM to 17:00PM, Mon to Friday."
        />
      </div>
    </div>
  );
};

export default Accordions;
