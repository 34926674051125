import React from 'react'

const Heading = ({ title, children, description }) => {
  return (
    <div className='heading-container  md:px-10 px-5  py-4 max-w-[1280px] mx-auto md:mt-4 mt-2'>
    <div className="max-container">
    <div className="info">
      <h3 className='lg:text-5xl md:text-4xl small:text-3xl text-[20px] text-primary font-bold '>{title} Airport Parking</h3>
      <p className='lg:text-[18px] text-[16px] text-secondary mt-4 font-medium'>{description}</p>
    </div>
    <div className="transparent-icon">{children}</div>
  </div>
  </div>
  )
}

export default Heading