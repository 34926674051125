import React, { useState } from 'react';

const TravelDetails = ({ form, setFormField, index, updateAirports }) => {
  const [isCheck, setIsCheck] = useState(true);

  return (
    <div className="mx-auto p-6 border border-secondary rounded-lg shadow-md bg-white mt-5">
      <h2 className="md:text-[32px] text-[18px] font-semibold mb-4">Flight Details</h2>
      <p className="text-sm mb-4">
        Do you have Terminal and flight details? 
        <label className="ml-2">
          <input
            type="radio"
            name="flightDetails"
            className="mr-1"
            checked={isCheck}
            onChange={() => setIsCheck(true)}
          /> Yes
        </label>
        <label className="ml-4">
          <input
            type="radio"
            name="flightDetails"
            className="mr-1"
            checked={!isCheck}
            onChange={() => setIsCheck(false)}
          /> No
        </label>
      </p>
      <hr className="mb-4 border-dashed border-primary" />

      {/* Conditionally render form with transition */}
      <div
        className={`transition-all duration-500 ${
          isCheck ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0 overflow-hidden'
        }`}
      >
        <form>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Departure Terminal*</label>
              <select
                className="w-full p-2 border border-secondary rounded-md bg-gray-100 focus:outline-none"
                value={form.departure_terminal}
                onChange={(e) => setFormField("departure_terminal", e.target.value)}
              >
                <option value="">Select Terminal</option>
                {updateAirports[index]?.terminal?.map((o, i) => (
                  <option key={`dep-t-${i}`}>{o.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Departure Flight No*</label>
              <input
                type="text"
                className="w-full p-2 border border-secondary rounded-md bg-gray-100 focus:outline-none"
                placeholder="departure flight no"
                name="Departure"
                value={form.departure_flight_no}
                onChange={(e) => setFormField("departure_flight_no", e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Arrival Terminal*</label>
              <select
                className="w-full p-2 border border-secondary rounded-md bg-gray-100 focus:outline-none"
                value={form.arrival_terminal}
                onChange={(e) => setFormField("arrival_terminal", e.target.value)}
              >
                <option value="">Select Terminal</option>
                {updateAirports[index]?.terminal?.map((o, i) => (
                  <option key={`arr-t-${i}`}>{o.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Arrival Flight No*</label>
              <input
                type="text"
                className="w-full p-2 border border-secondary rounded-md bg-gray-100 focus:outline-none"
                placeholder="arrival flight no"
                name="Return"
                value={form.arrival_flight_no}
                onChange={(e) => setFormField("arrival_flight_no", e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TravelDetails;
