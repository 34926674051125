import React from "react";
import { FaStar } from "react-icons/fa";

const Review = ({ review }) => {
  let rating = review?.overall;
  return (
    <>
      {review ? (
        <div className="single-review">
          <div className="review-row">
            <h3>{review.fullname}</h3>
            <div className="rating">
              {[...Array(5)].map((_, index) => {
                return (
                  <FaStar
                    key={index}
                    className={Math.floor(rating) > index ? "fill" : "none"}
                  />
                );
              })}
            </div>
          </div>
          <div className="comments">
            <p>{review.comments}</p>
          </div>
          <div className="date">
            <h4>Pusblish on: {review.publish_date}</h4>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Review;
