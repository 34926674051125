import React from 'react'
import { PhoneCall, Mail, MapPin } from "lucide-react";

const ContactUs = () => {
  return (
  <div className='contact'>
      <div className="relative w-full h-[400px] md:h-[500px] bg-cover bg-center" style={{ backgroundImage: "url('bg-contact.png')" }}>
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

    {/* Content */}
    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center">
      <h1 className="text-4xl md:text-5xl font-bold">Contact Us</h1>
     
    </div>

  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 px-4 md:px-12 py-10">
      {/* Call Us Card */}
      <div className="bg-white shadow-lg rounded-2xl p-6 flex flex-col items-center text-center transition-all duration-300 hover:bg-primary hover:text-white group">
        <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-4 transition-all duration-300 group-hover:bg-white">
          <PhoneCall className="text-blue-500 group-hover:text-primary" size={28} />
        </div>
        <h3 className="text-lg font-semibold group-hover:text-white">Call Us On</h3>
        <a href="tel:07413 818425" className="text-gray-600 mt-2 font-semibold group-hover:text-white">07413 818425</a>

      </div>

      {/* Email Us Card */}
      <div className="bg-white shadow-lg rounded-2xl p-6 flex flex-col items-center text-center transition-all duration-300 hover:bg-primary hover:text-white group">
        <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-4 transition-all duration-300 group-hover:bg-white">
          <Mail className="text-blue-500 group-hover:text-primary" size={28} />
        </div>
        <h3 className="text-lg font-semibold group-hover:text-white">Email Us</h3>
        <a  href="mailto:info@parking4you.co.uk" className="text-gray-600 font-semibold mt-2 group-hover:text-white">info@kangarooparking.co.uk</a>
  
      </div>

      {/* Our Location Card */}
      {/* <div className="bg-white shadow-lg rounded-2xl p-6 flex flex-col items-center text-center transition-all duration-300 hover:bg-primary hover:text-white group">
        <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-4 transition-all duration-300 group-hover:bg-white">
          <MapPin className="text-blue-500 group-hover:text-primary" size={28} />
        </div>
        <h3 className="text-lg font-semibold group-hover:text-white">Our Location</h3>
        <p className="text-gray-600 mt-2 group-hover:text-white">Lorem apsum</p>
        <p className="text-gray-600 group-hover:text-white">Lorem apsum Lorem apsum Lorem apsum</p>
      </div> */}
    </div>
  </div>
  )
}

export default ContactUs