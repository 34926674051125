import { useState } from "react";

const initialFrom = {
  title: "Mr",
  first_name: "",
  last_name: "",
  email: "",
  contact_no: "",
  no_of_peoples: 1,
  address: "",
  postcode: "",
  departure_terminal: "",
  departure_flight_no: "",
  arrival_terminal: "",
  arrival_flight_no: "",
};

const useBookingForm = (state = initialFrom) => {
  const [form, setForm] = useState(state);
  const setFromField = (label, value) => {
    setForm({
      ...form,
      [label]: value,
    });
  };

  return [form, setFromField, setForm];
};

export default useBookingForm;
