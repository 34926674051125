import React, { useState, useEffect } from "react";
import moment from "moment";

const ConfirmBookingSummary = ({ bookingDetails }) => {
  const [details, setDetails] = useState({});
  useEffect(() => {
    bookingDetails?.booking_details.map((detail) => {
      return setDetails(detail);
    });
  }, [bookingDetails]);

  const total =
    parseFloat(details.payment?.amount) +
    parseFloat(details.payment?.extra_charges) +
    parseFloat(details.payment?.levy_charges) +
    parseFloat(details.payment?.admin_charges) +
    parseFloat(details.payment?.cancellation_charges) +
    parseFloat(details.payment?.sms_charges) -
    parseFloat(details.payment?.discount);

  const qouteAmount =
    parseFloat(details.payment?.amount) +
    parseFloat(details.payment?.extra_charges) +
    parseFloat(details.payment?.levy_charges);

  const departure = moment(
    details.departure_date + " " + details.departure_time
  );

  const arrival = moment(details.arrival_date + " " + details.arrival_time);

  return (
    // <div>
    //   <div className="title">
    //     <h3>Booking Details</h3>
    //   </div>
    //   <div className="wrapper">
    //     <div className="company-info">
    //       <div className="title">
    //         <h1>{details.company?.title}</h1>
    //         <h3>{details.company?.type.title}</h3>
    //       </div>
    //       <div className="image">
    //         <img
    //           src={`${details.company?.image}`}
    //           alt={details.company?.name}
    //         />
    //       </div>
    //     </div>
    //     <div className="booking-details">
    //       <div className="col">
    //         <div className="row">
    //           <p>Traveling From:</p>
    //           <p className="value">{details.company?.airport.name} airport</p>
    //         </div>
    //         <div className="row">
    //           <p>Departure Date:</p>
    //           <p className="value">{departure.format("DD-MM-yyyy")}</p>
    //         </div>
    //         <div className="row">
    //           <p>Departure Time:</p>
    //           <p className="value">{departure.format("HH:mm")}</p>
    //         </div>
    //         <div className="row">
    //           <p>Arrival Date:</p>
    //           <p className="value">{arrival.format("DD-MM-yyy")}</p>
    //         </div>
    //         <div className="row">
    //           <p>Arrival Time:</p>
    //           <p className="value">{arrival.format("HH:mm")}</p>
    //         </div>
    //       </div>
    //       <div className="col">
    //         <div className="row">
    //           <p>Quote Amount:</p>
    //           <p className="value">£ {qouteAmount.toFixed(2)}</p>
    //         </div>
    //         <div className="row">
    //           <p>Discount Amount:</p>
    //           <div className="value">- £ {details.payment?.discount}</div>
    //         </div>
    //         <div className="row">
    //           <p>Admin Charges:</p>
    //           <p className="value">£ {details.payment?.admin_charges}</p>
    //         </div>
    //         <div className="row">
    //           <p>Cancellation Charges:</p>
    //           <p className="value">
    //             {parseFloat(details.payment?.cancellation_charges) > 0
    //               ? `£ `
    //               : ""}
    //             {parseFloat(details.payment?.cancellation_charges) > 0
    //               ? `${details.payment?.cancellation_charges}`
    //               : "0.00"}
    //           </p>
    //         </div>
    //         <div className="row">
    //           <p>SMS Charges:</p>
    //           <p className="value">
    //             {parseFloat(details.payment?.sms_charges) > 0 ? `£ ` : ""}
    //             {parseFloat(details.payment?.sms_charges) > 0
    //               ? `${details.payment?.sms_charges}`
    //               : "0.00"}
    //           </p>
    //         </div>
    //       </div>
    //       <div className="col">
    //         <div className="total-charges">
    //           <h3>Total</h3>
    //           <h2>£ {total.toFixed(2)}</h2>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="success-container flex flex-col items-center mt-8">
    <div className="title flex-1">
      <h3 className="text-3xl text-primary mb-7 font-semibold">
        Booking Details
      </h3>
    </div>
    <div className="wrapper flex lg:flex-row flex-col items-center justify-center flex-1 w-full">
      <div className="company-info lg:flex-[1.2_1_0%] flex-1 w-full lg:mb-0 mb-4">
        <div className="title px-5 flex flex-col items-center mb-3">
          <h1 className="text-sm text-secondary text-center font-semibold">
            {details.company?.name}
          </h1>
          <h3 className="text-sm font-medium text-primary">
            {details.company?.type}
          </h3>
        </div>
        <div className="image flex items-center justify-center">
          <img
            src={`${details.company?.image}`}
            alt={details.company?.name}
            width={150}
            height={150}
          />
        </div>
      </div>
      <div className="booking-details lg:flex-[3_1_0%] flex-1 w-full flex lg:flex-row flex-col items-center justify-center">
        <div className="col w-full flex-[1.2_1_0%] lg:pr-3 pr-0 lg:border-r-2 border-r-0 border-black lg:mb-0 mb-3">
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Traveling From:
            </p>
            <p className="value md:text-[15px] text-sm text-black">
              {details.company?.airport.name} airport
            </p>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Departure Date:
            </p>
            <p className="value md:text-[15px] text-sm text-black">
              {departure.format("DD-MM-yyyy")}
            </p>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Departure Time:
            </p>
            <p className="value md:text-[15px] text-sm text-black">
              {departure.format("HH:mm")}
            </p>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">Arrival Date:</p>
            <p className="value md:text-[15px] text-sm text-black">
              {arrival.format("DD-MM-yyy")}
            </p>
          </div>
          <div className="row flex items-center justify-between">
            <p className="md:text-[15px] text-sm text-black">Arrival Time:</p>
            <p className="value md:text-[15px] text-sm text-black">
              {arrival.format("HH:mm")}
            </p>
          </div>
        </div>
        <div className="col w-full flex-[1.2_1_0%] lg:px-3 px-0 lg:border-r-2 border-r-0 border-black lg:mb-0 mb-3">
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">Quote Amount:</p>
            <p className="value md:text-[15px] text-sm text-black">
              £ {qouteAmount.toFixed(2)}
            </p>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Discount Amount:
            </p>
            <div className="value md:text-[15px] text-sm text-black">
              - £ {details.payment?.discount}
            </div>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Admin Charges:
            </p>
            <p className="value md:text-[15px] text-sm text-black">
              £ {details.payment?.admin_charges}
            </p>
          </div>
          <div className="row flex items-center justify-between mb-2">
            <p className="md:text-[15px] text-sm text-black">
              Cancellation Charges:
            </p>
            <p className="value md:text-[15px] text-sm text-black">
              £ {details.payment?.cancellation_charges}
            </p>
          </div>
          <div className="row flex items-center justify-between">
            <p className="md:text-[15px] text-sm text-black">SMS Charges:</p>
            <p className="value md:text-[15px] text-sm text-black">
              £ {details.payment?.sms_charges}
            </p>
          </div>
        </div>
        <div className="col w-full flex-1 lg:border-t-0 border-t-2 lg:border-b-0 border-b-2 border-black">
          <div className="total-charges flex flex-col items-center">
            <h3 className="text-primary md:text-[30px] text-[20px] font-bold">
              Total
            </h3>
            <h2 className="text-secondary md:text-[30px] text-[20px] font-bold">
              £ {total.toFixed(2)}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ConfirmBookingSummary;
