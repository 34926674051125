let host = process.env.DOMAIN_URL;

if (typeof window !== "undefined") {
  host = window.location.origin;
  // console.log("host--------------", domainUrl);
}

const domainUrl = host;

export default domainUrl;
