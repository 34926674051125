import React from "react";
import Review from "./Review";

const Reviews = ({ product }) => {
  return (
    <>
      {product?.reviews ? (
        <div>
          {product.reviews?.map((review, index) => {
            return <Review key={index} review={review} />;
          })}
        </div>
      ) : <p className="text-secondary">No Reviews</p>}
    </>
  );
};

export default Reviews;
