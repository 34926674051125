import React from "react";
import { motion } from "framer-motion";

const AirplaneAnimation = () => {
  return (
    <motion.img
      src="/plane.png" // Place an airplane image in the public folder
      alt="Flying Airplane"
      initial={{ x: "-100%", y: "10vh", opacity: 0 }}
      animate={{ x: "100vw", opacity: 1 }}
      transition={{ duration: 5, ease: "linear", repeat: Infinity }}
      style={{
        position: "fixed",
        top: "20%",
        width: "100px",
        zIndex: 1000,
      }}
    />
  );
};

export default AirplaneAnimation;
