import React, { useState, useEffect } from "react";
import domainUrl from "../../../common/config/domainUrl";
import apiCall from "../../../common/config/apiCall";
import apiKey from "../../../common/config/apiKey";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { nextPage } from "../../../common/config/nextPage";
// Sweet alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
// End Sweet Alert

function StripeForm({
  handleSyncBooking,
  total,
  firstName,
  lastName,
  email,
  mobile,
  airport,
  discountCode,
  handleValidate,
  responseError,
  supplierCost,
  service,
  stripePaymentIntent,
  api_tag,
  reference_no,
  isVehicle,
  product,
  setAgree,
  agree,
  error,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState("initial");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationStatus = handleValidate();

    if (validationStatus?.length > 0) {
      return;
    }

    if (responseError.length > 0) {
      setState("error");
      setStatus("error");
      return;
    }
    if (validationStatus) {
      setState("loading");
      setStatus("");

      if (!stripe || !elements) {
        return;
      }
      let response = await handleSyncBooking();

      // console.log(response, 'response');
      // CHECK IF USER ALREADY HAS MADE THE PAYMENT THEN REDIRECT

      let validateIntent = await await apiCall(
        "post",
        "/stripe/paymentIntent/retrieve",
        { key: apiKey, payment_intent_id: stripePaymentIntent, api_tag },
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      );

      if (validateIntent?.status === "succeeded") {
        nextPage(
          `/success?bookingReference=${
            response?.multi_mode_reference_no
          }&reference_no=${
            response?.reference_no
          }&transactionID=${stripePaymentIntent}&paymentIntent=${stripePaymentIntent}&paymentMethod=Stripe&suppliercost=${supplierCost.toFixed(
            2
          )}&name=${firstName}${lastName}&email=${email}&mobile=${mobile}&totalamount=${total.toFixed(
            2
          )}&airport=${airport}&service=${service}&discountCode=${discountCode}&api_tag=${api_tag}`
        );
      }
      // Swal.fire({
      //   title: "<p>PROCESSING YOUR PAYMENT PLEASE WAIT.....</p>",
      //   allowOutsideClick: false,
      //   heightAuto: false,
      //   backdrop: true,
      //   didOpen: () => {
      //     MySwal.showLoading();
      //   },
      //   willClose: () => {
      //     clearInterval(2000);
      //   },
      // });
      // END CHECKING STRIPE

      if (response?.reference_no?.length) {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${domainUrl}/success?bookingReference=${
              response?.multi_mode_reference_no
            }&reference_no=${
              response?.reference_no
            }&transactionID=${stripePaymentIntent}&paymentIntent=${stripePaymentIntent}&paymentMethod=Stripe&suppliercost=${supplierCost.toFixed(
              2
            )}&name=${firstName}${lastName}&email=${email}&mobile=${mobile}&totalamount=${total.toFixed(
              2
            )}&airport=${airport}&service=${service}&discountCode=${discountCode}&api_tag=${api_tag}`,
          },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
          setStatus(error?.message || "Something went wrong");
          setState("initial");
          // Swal.close();
        } else {
          setStatus("An unexpected error occurred.");
          setState("initial");
          // Swal.close();
        }
        setState("initial");
        setState("error");
      }
    }
  };

  return (
    <div id="payment-form">
      {responseError?.length ? (
        <div className="response-error">
          <p className="text-[#FF0909] text-center">{responseError}</p>
        </div>
      ) : null}
      <div className="header-title flex items-center justify-center pb-5 pt-0">
        <h2 className="sm:text-3xl text-2xl text-secondary font-semibold capitalize">
          Payment details
        </h2>
      </div>
      <PaymentElement
        id="payment-element"
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                country: "gb",
              },
            },
          },
        }}
      />
<div className="flex items-start pt-5 gap-4">
  <div
    className={
      reference_no.length > 0
        ? "accept-content"
        : "opacity-60 pointer-events-none"
    }
  >
    <label className="relative cursor-pointer">
      <input
        type="checkbox"
        checked={agree}
        onClick={() => reference_no.length > 0 && setAgree(!agree)}
        className="peer hidden"
      />
      <div className="w-6 h-6 flex items-center justify-center rounded-md bg-secondary peer-checked:bg-secondary">
        {agree && <span className="text-white">✓</span>}
      </div>
      {error?.agree?.length ? (
        <p className="text-[13px] text-[#FF0909] font-medium">
          {error.agree}
        </p>
      ) : null}
    </label>
  </div>
  <div className="discount text-[14px]">
    <p
      className={
        reference_no.length > 0
          ? "accept-content md:text-base text-[14px] font-semibold cursor-pointer"
          : "opacity-60 pointer-events-none md:text-base text-[14px] font-semibold cursor-not-allowed"
      }
      onClick={() => reference_no.length > 0 && setAgree(!agree)}
    >
      I agree to the terms & conditions!
    </p>
  </div>
</div>
      <div className="btn-container flex items-center justify-center pt-5">
        <button
          className="payment-btn text-base w-[100%] py-3 px-5 bg-secondary  text-white font-medium rounded flex items-center justify-center"
          disabled={state === "loading" || !stripe || !elements}
          type="button"
          onClick={handleSubmit}
        >
          {state === "loading" ? (
            <p>Processing payment...</p>
          ) : (
            <span>Confirm Payment</span>
          )}
        </button>
      </div>
      {status ? (
        <div
          style={{
            background: "#f6f6f6",
            padding: "20px",
            textAlign: "center",
            borderRadius: "5px",
            color: "red",
            border: "1px solid red",
            marginTop: "30px",
          }}
        >
          <strong>{status?.toUpperCase()}</strong>
        </div>
      ) : null}
    </div>
  );
}

export default StripeForm;
