import React, { useEffect, useState } from 'react'
import CompanyContainer from '../containers/CompaniesContainer/CompaniesContainer'
import axios from 'axios';
import apiUrl from "../common/config/apiUrl";
import apiKey from "../common/config/apiKey";
import Seo from '../common/Components/Seo/Seo';

const Search = () => {
    const [airports, setAirports] = useState([]);

    useEffect(() => {
      (async () => {
        try {
          const response = await axios.get(`${apiUrl}/airports?key=${apiKey}`);
          if (response?.data?.response_code == 200) {
            setAirports(response?.data?.data);
          }
        } catch (error) {
          console.log("error", error);
        }
      })();
    }, []);
  return (
  <>
    <Seo
    seo={{
      title:
        "Search Page | Kangaroo Parking Heathrow ",
     
    }}/>
    <CompanyContainer airports={airports} setAirports={setAirports} /></>
  )
}

export default Search