import React from "react";
import { FaStar } from "react-icons/fa";
import "../../../styles/App.css";

const FormIntro = () => {
  return (
    <div className="flex-1 flex justify-between">
      <div className="flex-1">
        <div className="bg-[#3472cd20]">
          <h2 className=" md:text-[21px] font-normal text-[16px] text-white  mt-10 flex items-center gap-3">
            <FaStar className="text-primary" />
            We Make Heathrow Airport Parking Simple
            <FaStar className="text-primary" />
          </h2>
          <h2 className="passenger lg:text-[50px] md:text-[40px] text-[20px] font-semibold text-white">
            Convenient and Hassle-Free
          </h2>
          <h2 className="passenger lg:text-[50px] md:text-[40px] text-[20px] font-semibold text-white">
            Parking for Stress-Free
          </h2>
          <h2 className="passenger lg:text-[50px] md:text-[40px] text-[20px] font-semibold text-primary">
            Travel at Heathrow
          </h2>
          <div className="underline flex justify-start ">
            <img
              alt="underline"
              src={require("../../../common/images/underline-2.png")}
              className="w-[284px] h-[12px]"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end items-end gap-5">
        <img
          alt="underline"
          src={require("../../../common/images/hero-img-3.png")}
          className="w-[77px] h-[84px]"
        />
        <img
          alt="underline"
          src={require("../../../common/images/hero-img-2.png")}
          className="w-[77px] h-[84px]"
        />
        <img
          alt="underline"
          src={require("../../../common/images/hero-img-1.png")}
          className="w-[77px] h-[84px]"
        />
      </div>
    </div>
  );
};

export default FormIntro;
