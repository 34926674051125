import Cookies from "js-cookie";
export default function cookieHandler(cid) {
  let cookieResponse = "directAccess";
  if (cid) {
    switch (cid) {
      case "googleAds":
        cookieResponse = "googleAds";
        break;
      case "bingAds":
        cookieResponse = "bingAds";
        break;
      case "emailMarketing":
        cookieResponse = "emailMarketing";
        break;
      case "blockEmail":
        cookieResponse = "blockEmail";
        break;
      default:
        cookieResponse = "directAccess";
    }
  }

  Cookies.set("customCid", cookieResponse, { expires: 1 });

  return cookieResponse;
}
