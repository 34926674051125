import React, { useEffect, useState } from "react";
import { Sun, Moon } from "lucide-react";

const ThemeToggle = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div className="flex-1 flex justify-end">
      <div
        className="relative w-[52px] h-[100px] bg-[#D3E1E4] rounded-full p-1 flex flex-col items-center justify-between cursor-pointer transition-all"
        onClick={toggleTheme}
      >
        {/* Sun Icon (Changes Color Based on Theme) */}
        <Sun className={theme === "light" ? "text-primary" : "text-black"} size={28} />

        {/* Toggle Knob (Always Shows Sun or Moon) */}
        <div
          className={`absolute left-[6px] w-[40px] h-[40px] flex items-center justify-center bg-white rounded-full shadow-md transform transition-all duration-300 ${
            theme === "dark" ? "translate-y-[52px]" : "translate-y-0"
          }`}
        >
          {theme === "light" ? <Sun className="text-primary" size={20} /> : <Moon className="text-primary" size={20} />}
        </div>

        {/* Moon Icon (Changes Color Based on Theme) */}
        <Moon className={theme === "dark" ? "text-primary" : "text-black"} size={28} />
      </div>
    </div>
  );
};

export default ThemeToggle;
