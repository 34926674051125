import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cookieHandler from "../../libs/cookieHandler";
import { useInView } from "framer-motion";
import { nextPage } from "../../config/nextPage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import initializeDepartureDate from "../../libs/initializeDepartureDate";
import initializeArrivalDate from "../../libs/initializeArrivalDate";
import moment from "moment";
import times from "../../libs/times";

const UpdateSearch = ({ open, setOpen, refer, airports, defaultAirport }) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const location = useLocation();
    const pathname = location.pathname;
    const getDeparture = params.get("departure");
    const getArrival = params.get("arrival");
    const getPromocode = params.get("promocode");
    const getAirport = params.get("airport");
    const singleProduct = params.get("product");
    const getCustomCid = params.get("cid");
    const [state, setState] = useState("");
    const [validation, setValidation] = useState("");
    const [promocode, setPromoCode] = useState(getPromocode || "");
    const [airport, setAirport] = useState(getAirport || "LHR" || defaultAirport);
    const [departure, setDeparture] = useState(
      initializeDepartureDate(getDeparture || "")
    );
    const [arrival, setArrival] = useState(
      initializeArrivalDate(getArrival || "")
    );
    const dropRef = useRef(null);
    const pickerRef = useRef(null);
  
    const setDepartureDate = (date) => {
      let depTime = moment(date).format("HH:mm");
      let arrivalTime = moment(arrival).format("HH:mm");
  
      let depDate = moment(date).format("yyyy-MM-DD");
      let arrivalDate = moment(date).add(7, "days").format("yyyy-MM-DD");
  
      setDeparture(moment(`${depDate} ${depTime}`).format("yyyy-MM-DD HH:mm"));
      setArrival(
        moment(`${arrivalDate} ${arrivalTime}`).format("yyyy-MM-DD HH:mm")
      );
    };
  
    const setArrivalDate = (date) => {
      setArrival(moment(date).format("yyyy-MM-DD HH:mm"));
    };
  
    let airportsUpdate = [];
  
    //@ts-ignore
    if (airports?.length) {
      //@ts-ignore
      airports?.map((airport) =>
        airportsUpdate.push({
          label: airport?.name,
          value: airport?.code,
          terminal: airport.terminal,
        })
      );
    }
  
    const setTime = (time, mode) => {
      const [h, m] = time.split(":").map((v) => parseInt(v, 10));
  
      if (mode === "arrival") {
        setArrival(
          moment(arrival)
            .set("hours", h)
            .set("minutes", m)
            .format("yyyy-MM-DD HH:mm")
        );
      } else {
        setDeparture(
          moment(departure)
            .set("hours", h)
            .set("minutes", m)
            .format("yyyy-MM-DD HH:mm")
        );
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setValidation("");
  
      if (!airport) {
        setValidation("Please select an airport to continue");
        setState("error");
        return;
      }
      const params = new URLSearchParams({
        departure: departure,
        arrival: arrival,
        promocode: promocode,
        airport,
        product: singleProduct,
      });
  
      setState("loading");
      nextPage(`${refer}?${params}`);
      setOpen(false);
      setTimeout(() => setState("initial"), 2000);
      if (pathname === refer) {
        setState("");
      }
    };
  
    useEffect(() => {
      if (pickerRef.current !== null) {
        //@ts-ignore
        pickerRef.current.input.readOnly = true;
      }
  
      if (dropRef.current !== null) {
        //@ts-ignore
        dropRef.current.input.readOnly = true;
      }
  
      if (airports.length) {
        setValidation("");
      }
    }, [pickerRef, dropRef, airports]);
  
    const ref = useRef(null);
    const isView = useInView(ref);
  
    useEffect(() => {
      if (ref.current) {
        if (isView) {
          setTimeout(() => {
            //@ts-ignore
            ref.current.classList.add("visible");
          }, 200);
        } else {
          //@ts-ignore
          ref.current.classList.remove("visible");
        }
      }
    }, [isView]);
  
    useEffect(() => {
      if (airport?.length) {
        setValidation("");
      }
    }, [airport]);
  
    useEffect(() => {
      cookieHandler(getCustomCid);
    }, []);
  

  return (
    <div className=" flex  items-center justify-center ">
    <div className="bg-white rounded-xl p-6 w-[500px] relative">
     

      <div className="space-y-3">
        <div className="flex md:flex-row flex-col justify-between items-center gap-x-10 gap-y-5">
          <div className="flex flex-col flex-1 md:w-auto w-full">
            <label className="text-sm font-medium text-gray-700">
              Drop-off Date *
            </label>
            <DatePicker
              selected={departure}
              onChange={setDepartureDate}
              dateFormat="EEE, dd MMM yyyy"
              minDate={moment().toDate()}
              className="w-full border border-primary rounded-lg px-3 py-2 text-gray-700 focus:outline-none"
              ref={dropRef}
            />
          </div>
          <div className="flex flex-col md:flex-[0.5] flex-1 md:w-auto w-full">
            <label className="text-sm font-medium text-gray-700">
              Drop-off Time *
            </label>
            <select
              className="w-full border border-primary rounded-lg px-3 py-2 text-gray-700 focus:outline-none"
              onChange={(e) => setTime(e.target.value, "departure")}
              value={moment(departure).format("HH:mm")}
            >
              {times.map((o, index) => (
                <option key={index}>{o}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex  md:flex-row flex-col justify-between items-center gap-x-10 gap-y-5">
          <div className="flex flex-col flex-1 md:w-auto w-full">
            <label className="text-sm font-medium text-gray-700">
              Pick-up Date *
            </label>
            <DatePicker
              selected={arrival}
              onChange={setArrivalDate}
              dateFormat="EEE, dd MMM yyyy"
              minDate={departure}
              className="w-full border border-primary rounded-lg px-3 py-2 text-gray-700 focus:outline-none"
              ref={pickerRef}
            />
          </div>
          <div className="flex flex-col md:flex-[0.5] flex-1 md:w-auto w-full">
            <label className="text-sm font-medium text-gray-700">
              Pick-up Time *
            </label>
            <select
              className="w-full border border-primary rounded-lg px-3 py-2 text-gray-700 focus:outline-none"
              onChange={(e) => setTime(e.target.value, "arrival")}
              value={moment(arrival).format("HH:mm")}
            >
              {times.map((o, index) => (
                <option key={index}>{o}</option>
              ))}
            </select>
          </div>
        </div>


        <div>
          <label className="text-sm font-medium text-gray-700">Promo Code</label>
          <input
            type="text"
            placeholder="Promo Code"
            className="w-full border border-primary rounded-lg px-3 py-2 text-gray-700 focus:outline-none"
            value={promocode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </div>

        <button
          className="w-full bg-primary  text-white py-2 rounded-lg font-medium transition duration-200"
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  </div>
  );
};

export default UpdateSearch;
