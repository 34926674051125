import React, { useState, useEffect } from "react";
import { IoTriangleSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function BookingSteps() {
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);

    
    const scrollToTop = () => {
        document.documentElement.scrollTop = 150; // For Chrome, Firefox, IE, Opera
        document.body.scrollTop = 150; // For Safari
      };

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="bg-cover bg-no-repeat px-5 py-10"
            style={{ backgroundImage: "url('/bg-steps.png')" }}
        >
        <div className="max-w-[1280px] mx-auto flex flex-col md:flex-row md:items-center items-start gap-10">
        {/* Left Section */}
        <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="flex-1"
        >
            <h2 className="passenger lg:text-[35px] md:text-[25px]  text-[20px] font-bold text-secondary">
                Kangaroo Parking Heathrow
            </h2>
            <h2 className="passenger lg:text-[35px] md:text-[25px]  text-[20px] font-bold text-secondary">
               Hassle-Free Meet & Greet Parking
            </h2>
            <div className="underline flex justify-start py-2">
                <img
                    alt="underline"
                    src={require("../../../common/images/underline-3.png")}
                    className="w-[243px] h-[11px]"
                />
            </div>

            <p className="text-black md:text-[14px] text-[12px] leading-8 mt-4 md:w-[90%] w-full">
                Enjoy stress-free Heathrow Airport parking with our seamless meet and greet service. Our easy online booking and professional chauffeurs ensure your journey starts smoothly.
            </p>
            <p className="text-black md:text-[14px] text-[12px] leading-8 mt-4 md:w-[90%] w-full">
                Simply reserve your spot, drop off your car at the terminal, and let our trusted team take care of the rest—so you can travel with total peace of mind.
            </p>
            <button
                onClick={scrollToTop}
                className="classica text-[17px] bg-gradient-to-b from-secondary to-[#1A396700] border border-white rounded-[5px] text-white px-6 py-3 mt-4 bg-[#152c54] transition"
            >
                BOOK NOW
            </button>
        </motion.div>

        {/* Right Section - Steps */}
        <div className="flex-1 px-5">
            {[ // Steps Data
                { step: "01", title: "Complete Online Booking", text: "Reserve your parking space easily with our quick and secure online booking system. Just a few clicks and your spot are confirmed." },
                { step: "02", title: "Drop Off Your Car", text: "Drive straight to the terminal, hand over your keys, and our professional valet service will take care of the rest. Relax knowing your car is in safe hands." },
                { step: "03", title: "Enjoy Your Trip", text: "Board your flight stress-free while we monitor and secure your vehicle. Your car will be ready and waiting for you upon your return." },
            ].map((item, index) => (
                <motion.div
                    key={item.step}
                    className="w-full flex justify-center"
                    initial={{ opacity: 0, y: 40 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6, delay: index * 0.3, ease: "easeOut" }}
                >
                    <div
                        className={`flex items-center mb-6 shadow-lg p-4 relative md:w-[60%] w-full ${item.step === "02" ? "bg-secondary text-white" : "bg-white text-black"
                            }`}
                    >
                        <div className={`absolute left-[-20px] origin-center rotate-[-90deg] flex items-center gap-2 ${item.step === "02" ? "text-white" : "text-secondary"} py-6 rounded-md text-center`}>
                            <IoTriangleSharp
                                className={`${item.step === "02" ? "text-secondary" : "text-[#a09f9f]"} text-[40px] absolute left-[74px] top-[-4px]`}
                            />
                            <span className="block text-base uppercase">Step</span>
                            <span className="text-[46px] font-bold">{item.step}</span>
                        </div>
                        <div className="ml-12">
                            <h3 className="text-lg font-bold">{item.title}</h3>
                            <p className="text-sm mt-2">{item.text}</p>
                        </div>
                    </div>
                </motion.div>
            ))}
        </div>
    </div>
        </motion.div>
    );
}
