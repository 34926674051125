import React, { useEffect, useState } from "react";
import Search from "../containers/Index/Search/Search";
import NextTrip from "../containers/Index/NextTrip/NextTrip";
import AboutCompany from "../containers/Index/AboutCompany/AboutCompany";
import FamilyTravel from "../containers/Index/FamilyTravel/FamilyTravel";
import axios from "axios";
import apiUrl from "../common/config/apiUrl";
import apiKey from "../common/config/apiKey";
import BookingSteps from "../containers/Index/BookingSteps/BookingSteps";
import Seo from "../common/Components/Seo/Seo";

const Home = () => {
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${apiUrl}/airports?key=${apiKey}`);
        if (response?.data?.response_code == 200) {
          setAirports(response?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  return (
    <div>
      <Seo
        seo={{
          title:
            "Kangaroo Parking Heathrow | Meet & Greet Parking at All Terminals",
          description:
            "Enjoy hassle-free Meet & Greet parking at Heathrow Airport with Kangaroo Parking. We offer secure, convenient, and reliable parking services at all terminals. Book now for the best rates!",
          keywords:
            "Heathrow Meet and Greet parking, Heathrow airport parking, Kangaroo Parking Heathrow, secure airport parking, Heathrow terminal parking, reliable parking services, Heathrow parking deals",
        }}
      />
      <Search airports={airports} />
      <NextTrip />
      <AboutCompany />
      <FamilyTravel />
      <BookingSteps />
    </div>
  );
};

export default Home;
