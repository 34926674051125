import { FaMapMarkerAlt, FaCalendarAlt, FaClock, FaTag } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import initializeDepartureDate from "../../libs/initializeDepartureDate";
import initializeArrivalDate from "../../libs/initializeArrivalDate";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { nextPage } from "../../config/nextPage";
import { useInView } from "framer-motion";
import cookieHandler from "../../libs/cookieHandler";
import Select from "react-select/base";
import times from "../../libs/times";
import SelectAirport from "../SelectAirport/SelectAirport";

export default function AirportParkingSearch({
  open,
  setOpen,
  refer,
  airports,
  defaultAirport,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState("12:30");
  const [endTime, setEndTime] = useState("12:30");
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getPromocode = params.get("promocode");
  const getAirport = params.get("airport");
  const singleProduct = params.get("product");
  const getCustomCid = params.get("cid");
  const [state, setState] = useState("");
  const [validation, setValidation] = useState("");
  const [promocode, setPromoCode] = useState(getPromocode || "");
  const [airport, setAirport] = useState(getAirport || "LHR" || defaultAirport);
  const [departure, setDeparture] = useState(
    initializeDepartureDate(getDeparture || "")
  );
  const [arrival, setArrival] = useState(
    initializeArrivalDate(getArrival || "")
  );
  const dropRef = useRef(null);
  const pickerRef = useRef(null);

  const setDepartureDate = (date) => {
    let depTime = moment(date).format("HH:mm");
    let arrivalTime = moment(arrival).format("HH:mm");

    let depDate = moment(date).format("yyyy-MM-DD");
    let arrivalDate = moment(date).add(7, "days").format("yyyy-MM-DD");

    setDeparture(moment(`${depDate} ${depTime}`).format("yyyy-MM-DD HH:mm"));
    setArrival(
      moment(`${arrivalDate} ${arrivalTime}`).format("yyyy-MM-DD HH:mm")
    );
  };

  const setArrivalDate = (date) => {
    setArrival(moment(date).format("yyyy-MM-DD HH:mm"));
  };

  let airportsUpdate = [];

  //@ts-ignore
  if (airports?.length) {
    //@ts-ignore
    airports?.map((airport) =>
      airportsUpdate.push({
        label: airport?.name,
        value: airport?.code,
        terminal: airport.terminal,
      })
    );
  }

  const setTime = (time, mode) => {
    const [h, m] = time.split(":").map((v) => parseInt(v, 10));

    if (mode === "arrival") {
      setArrival(
        moment(arrival)
          .set("hours", h)
          .set("minutes", m)
          .format("yyyy-MM-DD HH:mm")
      );
    } else {
      setDeparture(
        moment(departure)
          .set("hours", h)
          .set("minutes", m)
          .format("yyyy-MM-DD HH:mm")
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidation("");

    if (!airport) {
      setValidation("Please select an airport to continue");
      setState("error");
      return;
    }
    const params = new URLSearchParams({
      departure: departure,
      arrival: arrival,
      promocode: promocode,
      airport,
      product: singleProduct,
    });

    setState("loading");
    nextPage(`${refer}?${params}`);
    setOpen(false);
    setTimeout(() => setState("initial"), 2000);
    if (pathname === refer) {
      setState("");
    }
  };

  useEffect(() => {
    if (pickerRef.current !== null) {
      //@ts-ignore
      pickerRef.current.input.readOnly = true;
    }

    if (dropRef.current !== null) {
      //@ts-ignore
      dropRef.current.input.readOnly = true;
    }

    // if (airports.length) {
    //   setValidation("");
    // }
  }, [pickerRef, dropRef]);

  const ref = useRef(null);
  const isView = useInView(ref);

  useEffect(() => {
    if (ref.current) {
      if (isView) {
        setTimeout(() => {
          //@ts-ignore
          ref.current.classList.add("visible");
        }, 200);
      } else {
        //@ts-ignore
        ref.current.classList.remove("visible");
      }
    }
  }, [isView]);

  useEffect(() => {
    if (airport?.length) {
      setValidation("");
    }
  }, [airport]);

  useEffect(() => {
    cookieHandler(getCustomCid);
  }, []);

  return (
    <div className="max-w-[1280px] mx-auto px-5 py-5 relative z-50 md:mt-0 mt-4">
      <div
        className="bg-white rounded-lg shadow-md md:h-[108px] h-auto overflow-hidden"
        onSubmit={handleSubmit}
        open={open}
      >
        <div className="flex md:flex-row flex-col h-full md:pt-0 pt-3">
          {/* Date */}
          <div className="flex flex-1 items-center">
            <div className="md:flex-1 flex-[1.5] flex flex-col pb-2 px-2  md:border-r-2 md:border-b-0 border-b-2 border-[#EAEAEA] border-opacity-50">
              <div className="md:text-center text-left py-1">
                <label className="text-[#102939] font-semibold gap-2 border-b-2 border-primary md:mb-0 mb-2">
                  Drop-of Date*
                </label>
              </div>
              <div className="flex items-center">
                <img
                  alt="phone"
                  src={require("../../images/date.png")}
                  className="sm:w-[35px] w-[20px] sm:h-[35px] h-[20px]"
                />
                <DatePicker
                  dateFormat="EEE, dd MMM yyyy"
                  selected={moment(departure).toDate()}
                  onChange={(date) => date && setDepartureDate(date)}
                  minDate={moment().toDate()}
                  //@ts-ignore
                  ref={dropRef}
                  showOnFocus={false}
                  className="w-full p-2 focus:outline-none md:px-6 px-2 border-opacity-50 text-[14px] font-medium"
                />
              </div>
            </div>

            {/* Time */}
            <div className="md:flex-[0.5] flex-1 flex flex-col pb-2 px-2 md:border-r-2 md:border-b-0 border-b-2 border-[#EAEAEA] border-opacity-50">
              <div className="md:text-center text-left py-1">
                <label className="text-[#102939] font-semibold  gap-2 border-b-2 border-primary md:mb-0 mb-2">
                  Time*
                </label>
              </div>

              <div className="flex items-center">
                <img
                  alt="phone"
                  src={require("../../images/time.png")}
                  className="sm:w-[35px] w-[20px] sm:h-[35px] h-[20px]"
                />
                <select
                  onChange={(e) => setTime(e.target.value, "departure")}
                  value={moment(departure).format("HH:mm")}
                  className="w-full p-2 focus:outline-none  border-opacity-50 text-[14px] font-medium"
                >
                  {times.map((o, index) => (
                    <option key={index}>{o}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-1 items-center">
            {/* Return Date */}
            <div className="md:flex-1 flex-[1.5] w-full flex-col pb-2 px-2 md:border-r-2 md:border-b-0 border-b-2 border-[#EAEAEA] border-opacity-50">
              <div className="md:text-center text-left py-1">
                <label className="text-[#102939] font-semibold gap-2 border-b-2 border-primary md:mb-0 mb-2">
                  Pick-Up Date*
                </label>
              </div>

              <div className="flex items-center">
                <img
                  alt="phone"
                  src={require("../../images/date.png")}
                  className="sm:w-[35px] w-[20px] sm:h-[35px] h-[20px]"
                />
                <DatePicker
                  dateFormat="EEE, dd MMM yyyy"
                  selected={moment(arrival).toDate()}
                  minDate={moment(departure).toDate()}
                  onChange={(date) => date && setArrivalDate(date)}
                  //@ts-ignore
                  ref={pickerRef}
                  className="w-full p-2 md:px-6 px-2 focus:outline-none  border-opacity-50 text-[14px] font-medium"
                />
              </div>
            </div>

            {/* Return Time */}
            <div className="md:flex-[0.5] flex-1 flex flex-col pb-2 px-2 md:border-r-2 md:border-b-0 border-b-2 border-[#EAEAEA] border-opacity-50">
              <div className="md:text-center text-left py-1">
                <label className="text-[#102939] font-semibold  gap-2 border-b-2 border-primary md:mb-0 mb-2">
                  Time*
                </label>
              </div>
              <div className="flex items-center">
                <img
                  alt="phone"
                  src={require("../../images/time.png")}
                  className="sm:w-[35px] w-[20px] sm:h-[35px] h-[20px]"
                />
                <select
                  onChange={(e) => setTime(e.target.value, "arrival")}
                  value={moment(arrival).format("HH:mm")}
                  className="w-full p-2 focus:outline-none  border-opacity-50 text-[14px] font-medium"
                >
                  {times.map((o, index) => (
                    <option key={index}>{o}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Promo Code */}
          <div className="md:flex-[0.5] w-full flex-col pb-2 px-3 flex justify-center">
            <div className="text-left py-1 md:px-2 px-0">
              <label className="text-[#102939] font-semibold  gap-2 border-b-2 border-primary md:mb-0 mb-2">
                Promo Code*
              </label>
            </div>
            <input
              type="text"
              className="w-full p-2 focus:outline-none  border-opacity-50 text-[14px]  font-medium"
              placeholder="Promo code"
              value={promocode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
          <div className="md:flex-[0.5] h-full w-full bg-cover">
            <button
              className={`text-white px-3 md:text-[29px] text-[20px] font-semibold md:py-0 py-2 flex items-center justify-center h-full w-full gap-3 ${
                pathname === "/" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={handleSubmit}
            >
              <img
                alt="phone"
                src={require("../../images/search-icon.png")}
                className="md:w-[28px] w-[20px] md:h-[28px] h-[20px]"
              />
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
