import React, { useState } from "react";
import { motion } from "framer-motion";

const NextTrip = () => {
  const [hoveredCardId, setHoveredCardId] = useState(null);
  
  const cards = [
    {
      id: 1,
      title: "Trusted & Reliable Parking",
      normalImage: require("../../../common/images/trust-2.png"),
      hoverImage: require("../../../common/images/trust.png"), 
      text: "Enjoy peace of mind with our dependable service.",
    },
    {
      id: 2,
      title: "24/7 Secure Parking",
      normalImage: require("../../../common/images/24-image-2.png"),
      hoverImage: require("../../../common/images/24-image.png"), 
      text: "Your vehicle is protected with round-the-clock CCTV monitoring.",
    },
    {
      id: 3,
      title: "Fast & Easy Booking",
      normalImage: require("../../../common/images/fast-img-2.png"),
      hoverImage: require("../../../common/images/fast-img.png"), 
      text: "Reserve your spot in just a few clicks.",
    },
    {
      id: 4,
      title: "Transparent Pricing",
      normalImage: require("../../../common/images/pricing-img-2.png"),
      hoverImage: require("../../../common/images/pricing-img.png"), 
      text: "Affordable rates with no hidden fees.",
    },
    {
      id: 5,
      title: "Exceptional Support",
      normalImage: require("../../../common/images/support-2.png"),
      hoverImage: require("../../../common/images/support.png"), 
      text: "Friendly customer service ready to assist you anytime.",
    },
  ];

  return (
    <motion.div
      className="max-w-[1280px] mx-auto h-full  text-black md:py-10 py-5 md:mt-6 mt-0 "
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: false, amount: 0.1 }}
      transition={{ duration: 0.5}}
    >
      <div className="w-full md:mt-0 mt-5 md:px-10 px-5">
        <h2 className="passenger lg:text-[35px] md:text-[25px]  text-[20px] font-bold text-center text-secondary">
          Kangaroo Parking Heathrow
        </h2>
        <div className="underline flex justify-center py-3">
          <img
            alt="underline"
            src={require("../../../common/images/underline.png")}
            className="w-[374px] h-[17px]"
          />
        </div>
        <p className="mt-4 md:text-[14px] text-[12px] text-black w-full pb-5 md:w-[60%] mx-auto leading-9 sm:text-center text-justify font-normal">
          We offer more than just a parking space. Whether you're going on a
          short trip or a long journey, rest assured your car is in safe hands
          with us. Our secure and convenient Heathrow Parking solutions are
          designed to make your travel worry-free.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 px-5">
        {cards.map((card, index) => {
          return (
            <motion.div
              key={card.id}
              className="flex justify-center"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div
                className="cursor-pointer flex  items-center justify-center p-2 border transition-all 
                bg-white border-[#70707030] shadow-md text-black border-gray-300 hover:bg-blue-500 
                hover:text-white hover:border-blue-500 w-full h-full py-5 rounded-lg"
                onMouseEnter={() => setHoveredCardId(card.id)}
                onMouseLeave={() => setHoveredCardId(null)}
              >
                <div className="flex-[0.5]  flex justify-center">
                  <img
                    alt="car"
                    src={hoveredCardId === card.id ? card.hoverImage : card.normalImage}
                    className="w-[47px] h-[45px]"
                  />
                </div>
                <div className="flex flex-[1.5] flex-col items-center justify-center gap-2">
                  <h3 className="mt-2 font-semibold text-center text-[13px]">{card.title}</h3>
                  <p className=" text-center text-[11px]">{card.text}</p>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default NextTrip;