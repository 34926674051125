import React from "react";

const Loader = ({ imageUrl }) => {
  return (
    <div className="fixed inset-0 z-[9999] bg-white/50 flex justify-center items-center">
      <div className="absolute top-1/2 transform -translate-y-1/2 z-[99999] text-center">
        <img
          src={imageUrl}
          alt="Loading"
          className="md:w-[500px] w-[300px] md:h-[260px] h-[170px]"
        />
      </div>
      <div className="fixed inset-0 bg-black/40 z-[9999]"></div>
    </div>
  );
};

export default Loader;
