import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import apiCall from "../../common/config/apiCall";
import apiKey from "../../common/config/apiKey";
import BookingDetails from "../Booking/BookingDetails";
import { ToastContainer } from "react-toastify";
import Loader from "../../common/Components/Loader/Loader";
import homeGif from "../../common/images/book-now.gif";

const PaymentPage = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getReference = params.get("reference_no");
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getAirport = params.get("airport");
  const getPromocode = params.get("promocode");
  const singleProduct = params.get("product");
  const sku = params.get("sku");
  const [site, setSite] = useState({});
  const [airports, setAirports] = useState([]);
  const [singleAirport, setSingleAirport] = useState({});
  const [product, setProduct] = useState({});
  const [state, setState] = useState("loading");
  const [cancellation, setCancellation] = useState(false);
  const [sms, setSMS] = useState(false);
  const [vehicles, setVehicles] = useState([
    {
      model: "",
      color: "",
      make: "",
      reg_no: "",
    },
  ]);

  useEffect(() => {
    if (Object.keys(product)?.length > 0) {
      if (product?.api_tag || (product?.option_id && product?.search_id)) {
        setVehicles([
          {
            model: product?.vehicle?.model,
            color: product?.vehicle?.color,
            make: product?.vehicle?.make,
            reg_no: product?.vehicle?.reg_no,
          },
        ]);
      } else {
        setVehicles([
          {
            model: product?.vehicle?.model,
            color: product?.vehicle?.color,
            make: product?.vehicle?.make,
            reg_no: product?.vehicle?.reg_no,
          },
        ]);
      }
    }
  }, [product]);

//   useEffect(() => {
//     (async () => {
//       setState("loading");
//       if (pathname == "/payment") {
//         if (getReference) {
//           const responseAirports = await await apiCall(
//             "get",
//             `/airports?key=${apiKey}`,
//             {},
//             {},
//             pathname
//           );

//           const responseProduct = await apiCall(
//             "get",
//             `/bookings/show/MM-${getReference}?key=${apiKey}&reference_no=${getReference}`,
//             {},
//             {},
//             pathname
//           );
//           const responseSite = await apiCall(
//             "get",
//             `/sites?${new URLSearchParams({
//               key: apiKey,
//               api_tag: responseProduct?.data[0]?.booking_details[0]?.api_tag,
//             })}`,
//             {},
//             {},
//             pathname
//           );

//           if (
//             responseAirports?.success &&
//             responseProduct?.success &&
//             responseSite?.success
//           ) {
//             setState("success");
//             setAirports(responseAirports?.data);
//             setProduct(responseProduct?.data[0]?.booking_details[0]);
//             setSite(responseSite?.data);
//             setSMS(
//               responseProduct?.data[0]?.booking_details[0]?.sms_confirmation ==
//                 1
//                 ? true
//                 : false
//             );
//             setCancellation(
//               responseProduct?.data[0]?.booking_details[0]
//                 ?.cancellation_status == 1
//                 ? true
//                 : false
//             );
//             const filterAirport = responseAirports?.data?.find((airport) => {
//               return (
//                 airport?.name ==
//                 responseProduct?.data[0]?.booking_details[0]?.company?.airport
//                   ?.name
//               );
//             });
//             if (filterAirport) {
//               setSingleAirport({
//                 id: filterAirport?.id,
//                 label: filterAirport?.name,
//                 value: filterAirport?.code,
//                 terminal: filterAirport?.terminal,
//               });
//             }
//           }
//         }
//       }
//     })();
//   }, [getReference, pathname]);

//   if (!product) {
//     return <Error message="Product not found" description="" />;
//   }
useEffect(() => {
    (async () => {
        setState("loading");
        if (pathname === "/payment" && getReference) {
            try {
                const responseAirports = await apiCall(
                    "get",
                    `/airports?key=${apiKey}`,
                    {},
                    {},
                    pathname
                );

                const responseProduct = await apiCall(
                    "get",
                    `/bookings/show/MM-${getReference}?key=${apiKey}&reference_no=${getReference}`,
                    {},
                    {},
                    pathname
                );

                

                const responseSite = await apiCall(
                    "get",
                    `/sites?${new URLSearchParams({
                        key: apiKey,
                        api_tag: responseProduct?.data?.[0]?.booking_details?.[0]?.api_tag,
                    })}`,
                    {},
                    {},
                    pathname
                );

                if (responseAirports?.success && responseProduct?.success && responseSite?.success) {
                    setState("success");
                    setAirports(responseAirports?.data);
                    setProduct(responseProduct?.data[0]?.booking_details[0]);
                    setSite(responseSite?.data);
                    setSMS(responseProduct?.data[0]?.booking_details[0]?.sms_confirmation === 1);
                    setCancellation(responseProduct?.data[0]?.booking_details[0]?.cancellation_status === 1);

                    const filterAirport = responseAirports?.data?.find((airport) => {
                        return airport?.name === responseProduct?.data[0]?.booking_details[0]?.company?.airport?.name;
                    });

                    if (filterAirport) {
                        setSingleAirport({
                            id: filterAirport?.id,
                            label: filterAirport?.name,
                            value: filterAirport?.code,
                            terminal: filterAirport?.terminal,
                        });
                    }
                } else {
                    console.error("API call failed");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    })();
}, [getReference, pathname]);


  return (
    <section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {Object.keys(site).length > 0 ? (
        product?.status !== "completed" ?
        <BookingDetails
          airports={airports}
          product={product}
          site={site}
          vehicles={vehicles}
          setVehicles={setVehicles}
          cancellation={cancellation}
          setCancellation={setCancellation}
          sms={sms}
          setSMS={setSMS}
          singleAirport={singleAirport}
        />
     :    <p className="sm:text-[35px] text-[20px] font-bold text-primary mt-5 flex justify-center items-center h-[200px]">
     {" "}
     Payment Already Completed
   </p>  ) : (
        <div>
        {state === "loading" && (
        <Loader imageUrl={homeGif} />
        )}
      </div>
      )}
    </section>
  );
};

export default PaymentPage;
