import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import DropOffProcedure from "./DropOffProcedure";
import NavLinks from "./NavLinks";
import Overview from "./Overview";
import PickUpProcedure from "./PickUpProcedure";
import Reviews from "./Reviews";
import TermsCondition from "./TermsCondition";

//import react icons
import { FaCar, FaStar } from "react-icons/fa";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { BsStarFill } from "react-icons/bs";

const contents = {
  overview: (product) => <Overview product={product} />,
  dropoffprocedure: (product) => <DropOffProcedure product={product} />,
  pickupprocedure: (product) => <PickUpProcedure product={product} />,
  reviews: (product) => <Reviews product={product} />,
  termscondition: (product) => <TermsCondition product={product} />,
};

const MoreInfo = ({ show, setShow, product }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getPromocode = params.get("promocode");
  const getAirport = params.get("airport");

  const [clicked, setClicked] = useState(false);
  const [content, setContent] = useState("overview");

  const rating = parseFloat(product.average_rating);

  const handleBookNow = () => {
    setClicked(true);
    navigate(
      `/booking?${new URLSearchParams({
        departure: getDeparture,
        arrival: getArrival,
        promocode: getPromocode,
        airport: getAirport,
        product: product?.sku.toString(),
      })}`
    );
  };

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      center
      classNames={{
        modal: "customModal md:max-w-[900px] mx-auto m-0 w-full bg-transparent shadow-none ",
      }}
      styles={{
        modal: { background: "transparent", boxShadow: "none", border: "none",margin: "0",  },
        overlay: { background: "rgba(0, 0, 0, 0.6)" }, 
        closeButton: { top: "35px", right:"25px",},
        margin: {left:"0", right:"0", }
      }}
      blockScroll
    >
      {/* <div>
        <div className="header">
          <div className="product-detail">
            <div className="img">
              <img src={product.image} alt={product.name} />
            </div>
            <div className="title">
              <h2>{product.name}</h2>
              <div className="rating-detail">
                <p>{product.type} Service</p>
                <div className="rating">
                  {[...Array(5)].map((_, index) => {
                    return (
                      <FaStar
                        key={index}
                        className={Math.floor(rating) > index ? "fill" : "none"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="price-detail">
            <h1>
              <span>£</span> {product.price}
            </h1>
            <button
              className={pathname === "/booking" ? "d-none" : "price-btn"}
              onClick={handleBookNow}
            >
              {clicked ? (
             <p>Loading....</p>
              ) : (
                <span>
                  <FaCar />
                  Book Parking
                </span>
              )}
            </button>
          </div>
        </div>
        <NavLinks content={content} setContent={setContent} />
        <div>{contents[content](product)}</div>
      </div> */}
      <div className="summary  bg-white flex items-center justify-between md:flex-row flex-col py-5 px-3 relative shadow-lg rounded-md">
            <div className="title flex flex-col items-center flex-1">
              <h2 className="text-primary text-center md:text-base text-sm font-semibold mb-3">
                {product?.name}
              </h2>
              {product?.offer ? (
                <div className="offer flex items-center justify-around bg-primary small:w-4/6 w-full mx-auto rounded-[3px] py-3 mb-4">
                  <p className="text-sm text-white capitalize font-light">
                    {product?.offer}
                  </p>
         
                </div>
              ) : null}
              {product?.average_rating ? (
                <div className="stars flex items-center">
                  {[...Array(5)].map((_, index) => {
                    return (
                      <BsStarFill
                        key={index}
                        className={
                          Math.floor(rating) > index
                            ? "text-base text-[#FFBE2E] mr-[3px]"
                            : "text-base text-darkGray mr-[3px]"
                        }
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="product-image flex justify-center flex-1 px-5 md:mt-0 mt-3">
              <img
                src={product?.image}
                alt={product?.name}
                className="w-4/6"
                width={20}
                height={20}
              />
            </div>
            <div className="booking-and-amount flex flex-col items-center flex-1 md:w-full sm:w-4/6 w-full md:mt-0 mt-3">
              <h4 className="text-3xl font-bold mb-3 text-secondary">£ {product?.price}</h4>
              <button
                className="text-base text-white font-semibold bg-primary py-[9px] px-10 capitalize rounded-[25px] shadow-[0_3px_3px_#0000007A]"
                onClick={handleBookNow}
              >
                Book now
              </button>
            </div>
          </div>
          <div className=" bg-white  flex items-start md:flex-row flex-col  py-5 px-3 mt-5 rounded-md">
            <NavLinks content={content} setContent={setContent}/>
            <div className="content flex-[2.5_1_0%] md:ml-5 ml-0 h-[400px] overflow-y-scroll">
              {contents[content](product)}
            </div>
          </div>
    </Modal>
  );
};

export default MoreInfo;
