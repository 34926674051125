import React from "react";
import { motion } from "framer-motion";

const FamilyTravel = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true, rootMargin: "-50px" }}
      className="max-w-[1280px] mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-5 md:px-16 py-10"
    >
      {/* Left Image */}
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        viewport={{ once: true, rootMargin: "-50px" }}
        className="md:w-1/2 flex justify-start mt-8 md:mt-0"
      >
        <img
          alt="car"
          src={require("../../../common/images/family-travel.png")}
          className="w-[80%]"
        />
      </motion.div>

      {/* Right Content */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true, rootMargin: "-50px" }}
        className="md:w-1/2 flex justify-end flex-col space-y-4"
      >
        <h2 className="passenger lg:text-[35px] md:text-[25px]  text-[20px] font-bold text-primary">
          Parking at Heathrow Long and Short Term
        </h2>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.3 }}
          viewport={{ once: true, rootMargin: "-50px" }}
          className="underline flex md:justify-start justify-start py-1"
        >
          <img
            alt="underline"
            src={require("../../../common/images/underline.png")}
            className="w-[243px] h-[11px]"
          />
        </motion.div>

        <p className="md:text-[14px] text-[12px] font-normal mt-2 md:w-[90%] w-full leading-8">
          Secure, reliable, and convenient parking solutions for stress-free travel.
        </p>

        {/* Features List */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3, ease: "easeOut" }}
          viewport={{ once: true, rootMargin: "-50px" }}
          className="grid md:grid-cols-2 grid-cols-1 gap-8 p-6 bg-[#F7F6F6]"
        >
          {[
            "Professional Heathrow Valet Parking",
            "Affordable Long-Term Parking Heathrow",
            "Convenient Meet & Greet Service",
            "Instant Booking Confirmation",
          ].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4, delay: 0.2 + index * 0.2 }}
              viewport={{ once: true, rootMargin: "-50px" }}
              className="flex items-center space-x-2 "
            >
              <img
                alt="pointing-right"
                src={require("../../../common/images/pointing-right.png")}
                className="w-[38px] h-[37px]"
              />
              <span className="md:text-[16px] text-[14px] font-medium">{item}</span>
            </motion.div>
          ))}

          {/* Full-Width Border Line */}
          <motion.div
            initial={{ opacity: 0, scaleX: 0 }}
            whileInView={{ opacity: 1, scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: true, rootMargin: "-50px" }}
            className="md:col-span-2 col-span-0 border-t border-primary my-2"
          ></motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default FamilyTravel;
