import React from "react";
import Heading from "../../common/Components/Heading/Heading";
import BookingForm from "./BookingForm/BookingForm";
import BookingSummary from "./BookingSummary/BookingSummary";
import moment from "moment";
import FourStepProcess from "../../common/Components/FourSteps";

const BookingDetails = ({
  product,
  site,
  airports,
  vehicles,
  setVehicles,
  cancellation,
  setCancellation,
  sms,
  setSMS,
  singleAirport,
  getDeparture,
  getArrival,
}) => {
  return (
    <div>
      <div className="md:block hidden md:mb-0 mb-4">
        {/* <FourStepProcess/> */}
      </div>
      <Heading
        title={
          product?.company
            ? product?.company?.airport?.name
            : product?.airport_name
        }
        description={`from ${moment(getDeparture).format(
          "ddd, DD MMM YYYY"
        )} to ${moment(getArrival).format("ddd, DD MMM YYYY")}`}
      />
      <div className="booking-details max-w-[1280px] mx-auto bg-transparent flex lg:flex-row flex-col justify-between md:px-10 px-5 md:py-10 py-5  md:gap-0 gap-5">
        <BookingForm
          setCancellation={setCancellation}
          cancellation={cancellation}
          sms={sms}
          setSMS={setSMS}
          vehicles={vehicles}
          setVehicles={setVehicles}
          site={site}
          product={product}
          airports={airports}
          singleAirport={singleAirport}
        />
        <BookingSummary
          cancellation={cancellation}
          sms={sms}
          product={product}
          vehicles={vehicles}
          airports={airports}
        />
      </div>
    </div>
  );
};

export default BookingDetails;
