const calculateTotalPrice = (product, price, cancellation, sms) => {
  //@ts-ignore
  let total = product?.payment
    ? price + parseFloat(product?.payment?.admin_charges)
    : price + parseFloat(product.admin_charges);
  if (cancellation) {
    product?.payment
      ? (total += parseFloat(product?.payment?.cancellation_charges))
      : (total += parseFloat(product.cancellation_charges));
  }
  if (sms) {
    product?.payment
      ? (total += parseFloat(product?.payment?.sms_charges))
      : (total += parseFloat(product.sms_charges));
  }
  if (product?.payment?.discount) {
    total -= parseFloat(product?.payment?.discount);
  }
  return total;
};

export default calculateTotalPrice;
