// import ScrollToView from "./scrollToView";

export default function validateBookingForm(form, vehicles, agree) {
  let state = "validated";
  let errors = [];

  // UserDetails Validation
  if (form.title.trim().length === 0) {
    state = "error";
    errors = [
      ...errors,
      {
        label: "title",
        message: "Title is required.",
        isValid: false,
      },
    ];
  }

  if (
    form.first_name.trim().length &&
    !form.first_name.match(/^[a-z ,.'-]+$/i)
  ) {
    state = "error";
    errors.push({
      label: "first_name",
      message: "Invalid first name",
      isValid: false,
    });
  }

  if (form.first_name.trim().length === 0) {
    state = "error";
    errors.push({
      label: "first_name",
      message: "First name field is required",
      isValid: false,
    });
  }

  if (form.last_name.trim().length && !form.last_name.match(/^[a-z ,.'-]+$/i)) {
    state = "error";
    errors.push({
      label: "last_name",
      message: "Invalid Last name",
      isValid: false,
    });
  }

  if (form.last_name.trim().length === 0) {
    state = "error";
    errors.push({
      label: "last_name",
      message: "Last name field is required",
      isValid: false,
    });
  }
  if (
    form.email.trim().length &&
    !form.email.match(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z.]{2,6}$/)
  ) {
    state = "error";
    errors.push({
      label: "email",
      message: "Invalid email address",
      isValid: false,
    });
  }
  if (form.email.trim().length === 0) {
    state = "error";
    errors.push({
      label: "email",
      message: "Email field is required",
      isValid: false,
    });
  }
  if (form.contact_no.trim().length && !form.contact_no.match(/^[0-9-+\s]+$/)) {
    state = "error";
    errors.push({
      label: "contact_no",
      message: "Invalid mobile number",
      isValid: false,
    });
  }
  if (form.contact_no.trim().length === 0) {
    state = "error";
    errors.push({
      label: "contact_no",
      message: "Mobile field is required",
      isValid: false,
    });
  }

  // Vehicle Validation
  vehicles?.map((vehicle) => {
    if (vehicle.make.length === 0) {
      state = "error";
      errors.push({
        label: "make",
        message: "Make is required",
        isValid: false,
      });
    }
    if (vehicle.make.length && !vehicle.make.match(/^[a-z ,.'-]+$/i)) {
      state = "error";
      errors.push({
        label: "make",
        message: "Make is invalid",
        isValid: false,
      });
    }
    if (vehicle.model.length === 0) {
      state = "error";
      errors.push({
        label: "model",
        message: "Model is required",
        isValid: false,
      });
    }
    if (vehicle.color.length === 0) {
      state = "error";
      errors.push({
        label: "color",
        message: "Color is required",
        isValid: false,
      });
    }
    if (vehicle.color.length && !vehicle.color.match(/^[a-z ,.'-]+$/i)) {
      state = "error";
      errors.push({
        label: "color",
        message: "Color is invalid",
        isValid: false,
      });
    }
    if (vehicle.reg_no.length === 0) {
      state = "error";
      errors.push({
        label: "reg_no",
        message: "Reg no is required",
        isValid: false,
      });
    }
  });

  // Terms and conditions validation

  if (!agree) {
    state = "error";
    errors.push({
      label: "agree",
      message:
        "Please agree to our terms and conditions to proceed with the booking.",
      isValid: false,
    });
  }

  //   if (
  //     form?.title.length &&
  //     form?.first_name.length &&
  //     form?.last_name.length &&
  //     form?.email.length &&
  //     form?.contact_no.length
  //   ) {
  //     ScrollToView(errors, "make", state);
  //   }

  //   if (
  //     !form?.title.length &&
  //     !form?.first_name.length &&
  //     !form?.last_name.length &&
  //     !form?.email.length &&
  //     !form?.contact_no.length
  //   ) {
  //     ScrollToView(errors, "email", state);
  //   }

  return {
    errors,
    state,
  };
}
