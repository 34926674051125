import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import apiCall from "../../common/config/apiCall";
import apiUrl from "../../common/config/apiUrl";
import apiKey from "../../common/config/apiKey";
import moment from "moment";
import Summary from "./Summary";
import Filter from "./Filter";
import Companies from "./Companies";

const CompanyContainer = ({ airports, setAirports }) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getPromocode = params.get("promocode");
  const getAirport = params.get("airport");
  const singleProduct = params.get("product");
  const [state, setState] = useState("loading");
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const [count, setCount] = useState(0);
  const currentRef = useRef(null);

  useEffect(() => {
    (async () => {
      if (currentRef.current) return;
      currentRef.current = true;
      if (getDeparture || getAirport || getArrival || getPromocode) {
        setState("loading");
        setError("");

        const responseAirport = await apiCall(
          "get",
          `/airports?key=${apiKey}`,
          {},
          {},
          pathname
        );

        if (responseAirport?.success) {
          setAirports(responseAirport?.data);
        }

        const response = await apiCall(
          "get",
          `/productsUpdated?key=${apiKey}&departure=${`${moment(
            getDeparture
          ).format("DD-MM-yyyy")} ${moment(getDeparture).format(
            "HH:mm"
          )}`}&arrival=${`${moment(getArrival).format("DD-MM-yyyy")} ${moment(
            getArrival
          ).format("HH:mm")}`}&discount_code=${
            getPromocode?.toString() || ""
          }&airport=${"LHR" || ""}&return_response=${"full"}`,
          {},
          {},
          pathname
        );
        if (!response.success) {
          setError(response.message);
          setState("error");
        }
        if (response.data?.length > 0) {
          let newArray = [];
          let filtered = response?.data
            ?.filter((product) => product.offer === "recommended")
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
          newArray = [...filtered];

          let specialOffer = response?.data
            ?.filter((product) => product.offer === "special-offer")
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

          newArray.push(...specialOffer);

          let otherOffer = response?.data
            ?.filter(
              (product) =>
                product.offer !== "recommended" &&
                product.offer !== "special-offer" &&
                product.offer?.length
            )
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

          newArray.push(...otherOffer);

          let nonFiltered = response?.data
            ?.filter((product) => product.offer === null)
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
          newArray.push(...nonFiltered);
          setProducts(newArray);
        }
        setAllProducts(response.data || []);
        setCount(response?.data?.length);
        setState("success");
      }
    })();
  }, [getDeparture, apiKey, getAirport, getArrival, getPromocode]);

  const handleSort = (e) => {
    let value = e.target.value;

    let sortingProducts = [...allProducts];
    let sortingFilter = [];

    sortingProducts.sort((a, b) => {
      return value === "0"
        ? parseFloat(a.price) - parseFloat(b.price)
        : parseFloat(b.price) - parseFloat(a.price);
    });

    let matchRecommend = sortingProducts.filter((product) => {
      return product.offer === "recommended";
    });

    sortingFilter = [...matchRecommend];

    let unMatchRecommend = sortingProducts.filter((product) => {
      return product.offer !== "recommended";
    });

    sortingFilter.push(...unMatchRecommend);

    setProducts(sortingFilter);
  };

  const handleFilter = (e) => {
    setSelectedValue(e.target.value);
  };

  const filtered = () => {
    let data = [...allProducts];

    if (selectedValue === "all") {
      let newArray = [];
      let filtered = data
        ?.filter((product) => product.offer === "recommended")
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      newArray = [...filtered];

      let nonFiltered = data
        ?.filter((product) => product.offer !== "recommended")
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      newArray.push(...nonFiltered);
      setCount(newArray?.length);
      setProducts(newArray);
    } else {
      let filterItem = allProducts?.filter(
        (item) => selectedValue === item.type
      );
      let typeMatchingItemSort = [];
      let filterMatch = filterItem
        .filter((product) => {
          return product.offer === "recommended";
        })
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      typeMatchingItemSort = [...filterMatch];
      let filterNonRecommend = filterItem
        .filter((product) => {
          return product.offer !== "recommended";
        })
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      typeMatchingItemSort.push(...filterNonRecommend);
      setProducts(typeMatchingItemSort);
      setCount(filterItem?.length);
    }
  };

  useEffect(() => {
    filtered();
  }, [selectedValue]);

  return (
    <div className="product-container" state={state}>
      <Summary airports={airports} />
      <Filter
        count={count}
        handleSort={handleSort}
        handleFilter={handleFilter}
      />
      <Companies products={products} state={state} error={error} />
    </div>
  );
};

export default CompanyContainer;
