import React from 'react'
import PaymentPage from '../containers/PaymentPage/PaymentPage'

const Payment = () => {
  return (
    <div>
        <PaymentPage/>
    </div>
  )
}

export default Payment