import { useState, useEffect } from "react";
import { HiMenu, HiX } from "react-icons/hi";

const BottomHeader = ({open, setOpen}) => {
  const [activePath, setActivePath] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => setActivePath(window.location.pathname);
    window.addEventListener("popstate", handleRouteChange);
    return () => window.removeEventListener("popstate", handleRouteChange);
  }, []);

  const navItems = ["Home","FAQs", "Contact Us"];

  return (
    <nav className=" px-5  max-w-[1280px] mx-auto w-full ">
      <div className="flex justify-between md:items-start items-center mx-auto relative">
        {/* Logo */}
     
        <a key="home"  href="/" onClick={() => setActivePath("/")} className="py-2">
          <img src="/white-logo.png" alt="Park Plus Logo" className="h-[70px] md:h-[131px] md:w-[228px] w-auto" />
        </a>

        <div className="md:block hidden border-b border-white h-[1px] absolute top-[83px]
    w-[81%]
    right-[0] "></div>
        <button
          className="md:hidden text-2xl text-gray-700"
          onClick={() => setOpen(!open)}
        >
          {open ? <HiX className="text-primary" /> : <HiMenu className="text-primary" />}
        </button>

        {/* Desktop Navigation */}
        <div className="hidden md:flex flex-[0.4] justify-evenly px-5 text-white  ">
          {navItems.map((item, index) => {
            const path = item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, "-")}`;
            return (
              <a
                key={index}
                href={path}
                className={`text-gray-700 py-10 font-semibold  flex items-center px-[5px] hover:text-primary h-[60px] ${
                  activePath === path ? "text-white bg-[#7d92f1] bg-opacity-50  border-b-4 border-white" : "hover:text-white "
                }`}
                onClick={() => setActivePath(path)}
              >
                {item}
              </a>
            );
          })}
        </div>
      </div>

      {/* Mobile Dropdown */}
      {open && (
  <div className="md:hidden bg-white shadow-lg rounded-md p-3   relative top-[0px] left-0 right-0 w-full mt-5 ">
    {navItems.map((item, index) => {
      const path = item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, "-")}`;
      return (
        <a
          key={index}
          href={path}
          className={`block text-gray-700 py-2 font-medium hover:text-black ${
            activePath === path ? "text-primary font-bold" : ""
          }`}
          onClick={() => {
            setOpen(false);
            setActivePath(path);
          }}
        >
          {item}
        </a>
      );
    })}
  </div>
)}

    </nav>
  );
};

export default BottomHeader;
