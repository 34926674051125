import React from "react";
import parse from "html-react-parser";

const PickUpProcedure = ({ product }) => {
  return (
    <>
      {product?.return_procedure ? (
        <div>{parse(product.return_procedure)}</div>
      ) : null}
    </>
  );
};

export default PickUpProcedure;
