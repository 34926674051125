import React from 'react'
import TermsAndConditions from '../containers/TermsAndCondition/TermsAndCondition'
import Seo from '../common/Components/Seo/Seo'

const TermsAndCondition = () => {
  return (
    <div>
       <Seo
        seo={{
          title: "Terms And Condition Page | Kangaroo Parking Heathrow",
        }}
      />
        <TermsAndConditions/>
    </div>
  )
}

export default TermsAndCondition