import { useState } from "react";
import { PlusCircle } from "lucide-react";

const VehicleDetails = ({
  id,
  index,
  vehicles,
  setVehicles,
  handleChange,
  errors,
  product,
}) => {
  const [showForm, setShowForm] = useState(true);

  const handleRadioChange = (e) => {
    setShowForm(e.target.value === "yes");
  };

  const handleClick = (e, id) => {
    const updatedVehicles = vehicles.map((vehicle, idx) =>
      idx === id
        ? {
            ...vehicle,
            [e.target.name]: e.target.value === "TBC" ? "" : e.target.value,
          }
        : vehicle
    );
    setVehicles(updatedVehicles);
  };

  const handleBlur = (e, id) => {
    const { name, value } = e.target;
    setVehicles((prevVehicles) =>
      prevVehicles.map((vehicle, idx) => {
        if (idx === id) {
          return {
            ...vehicle,
            [name]: value || ((product?.api_tag || product?.option_id) ? "" : "TBC"),
          };
        }
        return vehicle;
      })
    );
  };
  

  const handleOrdinalSuffixOf = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) return i + "st";
    if (j === 2 && k !== 12) return i + "nd";
    if (j === 3 && k !== 13) return i + "rd";
    return i + "th";
  };

  return (
    <div className="mx-auto p-6 border border-secondary rounded-lg shadow-md bg-white mt-5">
      <h3 className="md:text-[32px] text-[18px] font-semibold mb-4">
        {vehicles.length > 1 ? handleOrdinalSuffixOf(index + 1) : null} Vehicle Details?
      </h3>

      {/* Radio buttons */}
      <p className="text-sm mt-2">
        Do you have vehicle details?
        <label className="ml-2">
          <input
            type="radio"
            name={`vehicleDetails-${index}`}
            value="yes"
            className="mr-1"
            checked={showForm}
            onChange={handleRadioChange}
          />{" "}
          Yes
        </label>
        <label className="ml-4">
          <input
            type="radio"
            name={`vehicleDetails-${index}`}
            value="no"
            className="mr-1"
            checked={!showForm}
            onChange={handleRadioChange}
          />{" "}
          No
        </label>
      </p>

      {/* Vehicle details form */}
      {showForm && (
        <div className="transition-opacity duration-500 ease-in opacity-100 mt-4">
          <hr className="mb-4 border-dashed border-primary mt-4" />
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4">
            {["make", "model", "color", "reg_no"].map((field) => (
              <div key={field} className="input-field">
                <label className="block text-sm font-medium mb-1">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")} *
                </label>
                <input
                  type="text"
                  name={field}
                  placeholder={field.replace("_", " ")}
                  className={`w-full p-2 border border-secondary rounded-md bg-gray-100 focus:outline-none ${
                    errors?.some((error) => error.label === field)
                      ? vehicles[index][field]?.length
                        ? "border-error-none"
                        : "border-error"
                      : ""
                  }`}
                  value={vehicles[index][field] || ""}
                  onChange={(e) => handleChange(e, id)}
                  onClick={(e) => handleClick(e, id)}
                  onBlur={(e) => handleBlur(e, id)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleDetails;
