export function nextPage(route, target) {
  if (typeof window !== "undefined") {
    if (target == "_blank") {
      const origin = window.location.origin;
      window.open(`${origin}${route}`, target);
    } else {
      const origin = window.location.origin;
      window.location.href = `${origin}${route}`;
    }
  }
}
