import React from "react";
import { motion } from "framer-motion";

const TravelSection = () => {
  const features = [
    "Park Mark, Secured Car Park Facility.",
    "Convenient & Flexible Parking Solutions ",
    "Exceptional Customer Support",
    "Effortless Meet & Greet Parking at Heathrow",
  ];

const scrollToTop = () => {
  document.documentElement.scrollTop = 150; // For Chrome, Firefox, IE, Opera
  document.body.scrollTop = 150; // For Safari
};

  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="max-w-[1280px] mx-auto flex flex-col md:flex-row items-start justify-between px-5 pt-5"
    >
      {/* Left Content */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="md:w-1/2 space-y-4"
      >
        <h2 className="passenger lg:text-[35px] md:text-[25px]  text-[20px] font-bold text-primary">
          Kangaroo -- Meet and Greet Heathrow Parking Terminal 2,3,4 and 5
        </h2>
        <div className="underline flex justify-start py-1">
          <img
            alt="underline"
            src={require("../../../common/images/underline.png")}
            className="w-[243px] h-[11px]"
          />
        </div>

        <p className="md:text-[14px] text-[12px] md:w-[90%] w-full leading-8">
          Kangaroo Parking Heathrow prioritize the safety and convenience of our customers. With years of trusted service, we’ve been the go-to parking choice for travellers at Heathrow Airport.
        </p>

        {/* Stats & Features */}
        <div className="flex flex-col items-start justify-between w-full mx-auto">
          <div className="flex md:flex-row flex-col gap-5">
            {/* Stats Box */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              className="mt-2"
            >
              <div className="p-6 border-l-4 border-black shadow-[0px_4px_16px_#CDEBEE] bg-white rounded-md">
                <p className="text-black text-[14px]">Trusted by</p>
                <h2 className="text-[62px] font-bold text-blue-600">18K</h2>
                <p className="text-black text-[17px] font-medium">Happy Customers</p>
              </div>

              {/* Button Animation */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="button mt-6 mx-2"
              >
                <button
                  onClick={scrollToTop}
                  className="classica bg-gradient-to-b from-secondary to-[#1A396700] border border-white mt-4 bg-[#152c54] text-[17px] uppercase w-full text-white px-[6px] py-3 rounded-md font-semibold shadow-md  transition flex justify-center"
                >
                  Book Now
                </button>
              </motion.div>
            </motion.div>

            {/* Features List */}
            <div className="space-y-2">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="flex items-center space-x-2"
                >
                  <img
                    alt="icon"
                    src={require("../../../common/images/list-disc.png")}
                    className="w-[46px] h-[46px]"
                  />
                  <p className="text-black text-[13px] font-semibold">{feature}</p>
                </motion.div>
              ))}

              {/* Contact Section */}
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="flex items-center space-x-2 text-lg font-semibold text-gray-700"
              >
                <img
                  alt="phone"
                  src={require("../../../common/images/phone-number.png")}
                  className="w-[49px] h-[49px]"
                />
                <div className="number-contact">
                  <p className="text-[16px] font-normal">Call Us</p>
                  <span className="text-blue-600 text-xl font-bold">
                    <a href="tel:07413 818425">07413 818425</a>
                  </span>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Right Image with Animation */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
        className="md:w-1/2 flex justify-center mt-8 md:mt-0"
      >
        <img
          alt="car"
          src={require("../../../common/images/family.png")}
          className="w-[80%]"
        />
      </motion.div>
    </motion.div>
  );
};

export default TravelSection;
