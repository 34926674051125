import React, { useState } from "react";
import parse from "html-react-parser";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import apiKey from "../../common/config/apiKey";
import apiCall from "../../common/config/apiCall";
import { BsInfoCircleFill } from "react-icons/bs";
import {  FaClock, FaBus, FaKey } from "react-icons/fa";

import bookNow from "../../common/images/book-now.gif";

// react icon
import { FaStar, FaCar, FaThumbsUp } from "react-icons/fa";
// import MoreInfo from "../../common/Components/MoreInfo/MoreInfo";
import { nextPage } from "../../common/config/nextPage";
import Loader from "../../common/Components/Loader/Loader";
import MoreInfo from "../../common/Components/MoreInfo/MoreInfo";

const Grid = ({ product }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;
  const getDeparture = params.get("departure");
  const getArrival = params.get("arrival");
  const getPromocode = params.get("promocode");
  const getAirport = params.get("airport");

  const [show, setShow] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [singleProductDetail, setSingleProductDetail] = useState({});

  const rating = parseFloat(product.average_rating);

  const handleBookNow = () => {
    setClicked(true);
    nextPage(
      `/booking?${new URLSearchParams({
        departure: getDeparture,
        arrival: getArrival,
        promocode: getPromocode,
        airport: getAirport,
        product: product?.sku.toString(),
      })}`
    );
  };


  const placeholderImage =
    "https://www.pngitem.com/pimgs/m/599-5998799_block-icon-clipart-hd-png-download.png";
  // 'https://hanger.binaryops.co/images/placeholder.jpeg';

  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };

  const handleCallSingleProduct = async () => {
    const response = await apiCall(
      "get",
      `/product?key=${apiKey}&departure=${`${moment(getDeparture).format(
        "DD-MM-yyyy"
      )} ${moment(getDeparture).format("HH:mm")}`}&arrival=${`${moment(
        getArrival
      ).format("DD-MM-yyyy")} ${moment(getArrival).format("HH:mm")}`}&sku=${
        product?.sku
      }&discount_code=${getPromocode?.toString() || ""}&airport=${
        getAirport?.toString() || ""
      }&return_response=${"full"}`,
      {},
      {},
      pathname
    );
    if (response) {
      setSingleProductDetail(response?.data);
      setShow(true);
    }
  };



  return (
    <div className=" mt-[100px] mx-auto   sm:max-w-sm w-full bg-white rounded-[50px] shadow-[6px_6px_0px_#F2F2F2] p-4 border border-secondary relative md:z-0 z-50">
    {/* Top Logo Section */}
    <div className="relative flex justify-center sm:w-[316px] w-full mx-auto ">
      <img
          src={product.image}
            alt={product.name}
        className="w-[280px] h-[108px] object-contain bg-[#FBFBFB] rounded-[34px] px-10 py-1 shadow-md absolute -top-[100px]  border-secondary border-[0.5px]"
       onError={onImageError}
        />
    </div>

    {/* Card Content */}
    <div className="mt-6 flex flex-col justify-between h-[95%]">
      {/* Offer Badge */}
      {product?.offer && (
        <div className="flex items-center space-x-2 bg-gray-100 text-gray-700 text-xs font-semibold px-3 py-1 rounded-md w-fit">
          <FaThumbsUp className="text-yellow-500" />
          <span>{product?.offer}</span>
        </div>
      )}

      {/* Car Park Name */}
      <h4 className="text-[18px] font-bold text-secondary mt-2">
        {product?.name}
      </h4>
      <p className="text-[14px] font-medium text-white  bg-primary  px-2 py-[3px] rounded-2xl mt-3 w-[130px] text-center">
        {product?.display_name}
      </p>

    

      {/* Features List */}
{/* Features List */}
<ul className="text-sm text-black py-5 mt-1 space-y-1">
  {product?.short_description ? (
    <ul className="features">
      {parse(product.short_description, {
        replace: (domNode) => {
          if (domNode.name === "li") {
            const textContent = domNode.children
              ?.map((child) => (typeof child === "string" ? child : child.data))
              .join("")
              .replace(/:/g, ""); // Removes all colons from the text

            return (
              <div className="flex items-start gap-2 mt-2">
                <img
                  src={require("../../common/images/arrow.png")}
                  alt="arrow"
                  className="h-[14px] w-[14px]"
                />
                <span>{textContent}</span>
              </div>
            );
          }
        },
      })}
    </ul>
  ) : null}
</ul>




  

   {/* <div className="flex gap-3 py-5">

      <div className="w-[40px] h-[40px] flex items-center justify-center border-2 border-[#66A4AC] rounded-md shadow-md cursor-pointer">
        <FaCar className="text-black text-[18px]" />
      </div>


      {[FaClock, FaBus, FaKey].map((Icon, index) => (
        <div
          key={index}
          className="w-[40px] h-[40px] flex items-center justify-center border-2 border-gray-300 bg-gray-100 rounded-md opacity-30 cursor-not-allowed"
        >
          <Icon className="text-gray-400 text-2xl" />
        </div>
      ))}
    </div> */}

      {/* Price & Book Button */}
     
<div >
<div className="flex items-center justify-center border-b border-secondary  pb-3">
   
   {/* <div className="flex items-center mt-3" >
    <div className="flex text-[#FFC400]">
      {[...Array(5)].map((_, index) => (
        <FaStar
          key={index}
          className={Math.floor(rating) > index ? "fill" : "opacity-30"}
        />
      ))}
    </div>
    <span className="ml-2 text-sm text-gray-700 font-semibold">
      {rating ? rating?.toFixed(1) : 0 }/5
    </span>
  </div> */}

  {/* More Info Button */}
  <button
  className="mt-3 text-sm flex items-center gap-2 font-semibold py-1 rounded-md px-4 bg-white text-black shadow-sm"
  onClick={handleCallSingleProduct}
  style={{

    border:"1px solid #000000"
  }}
>
  More Info <span className="text-black"><BsInfoCircleFill /></span>
</button>

  <MoreInfo
          show={show}
          setShow={setShow}
          product={singleProductDetail}
        />
</div>

{product?.discount > 0 && (
        <p className="mt-2 text-sm bg-primary p-2 rounded-md font-medium">
          Quote was £{product?.price_before_discount}, Saved £
          {product?.discount} on this Parking
        </p>
      )}
<div className="mt-4 flex justify-between items-center">
        <h2 className="text-[30px] font-bold text-black">
          <span className="text-[30px]  ">£</span> {product?.price}
        </h2>
        <button
          type="submit"
          className="flex items-center bg-secondary text-white px-4 py-2 rounded-lg shadow font-semibold "
          onClick={handleBookNow}
        >
          <FaCar className="mr-2" />
          Book Now
        </button>
      </div>
</div>
      {/* Discounted Price Info */}
    
    </div>
  </div>
    
  );
};

export default Grid;
